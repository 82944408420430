.cookiebar {
  background: rgba($color-tertiary, 0.9);
  font-size: .9rem;
  text-align: left;
  max-width: 350px;
  line-height: normal;
  bottom: 20px;
  left: auto;
  right: 20px;
  padding: 20px;
  justify-content: left;
  .cookiebar__button {
    background: none;
    border: solid 1px #fff;
    margin-top: 10px;  
    color: #fff;
    display: block;
    &:hover {
      background: none;
      color: #fff;
    }
  }
  a {
    display: block;
    &:hover {
      text-decoration: underline;
  }
} 
}

.cookiebar__message, .cookiebar__text {
  margin: 0;
}