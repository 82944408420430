/*========================Footer======================*/
.footer {
	background: #edf1f2;
	color: #313131;
			h3{
			color: $color-primary;
		}
			a {
				color: #313131;
				&:hover {
					color: $color-primary;
				}
			}
	}


	.id-copyright {
		padding: 15px 0;
		border-top: solid 1px #fff;
		font-size: 0.8rem;
		text-align: center;
		@include responsive(phone) {
			text-align: unset;
		}
	}

.id-social-icons {
	.inner {
		font-size: 1.5rem;
		display: flex;
		align-items: center;
		i {
			padding-right: 20px;
		}
	}
}

.footer__inner {
	padding: 80px 15px;
}
