.id-form_small {
	input[type="text"],input[type="email"], textarea {
		background: #fff;
		border: solid 1px #313131;
		width: 100%;
		color: #313131;
		padding: 15px;
		margin-bottom: 15px;
		&:focus {
			border: solid 1px $color-primary;
			transition: .4s;
			box-shadow: none;
		}
	}
}



/* --------------------------
Form Styling
--------------------------- */
.contact-form {
	width: 100%;
	overflow: visible;
	form {
	margin-top: 20px;
	padding: 20px 0;
}
	.inner{
		margin: auto;
		color: #000;
		display: flex;
		flex-direction: column;
		h1 {
			color: #fff;
			background: #f87309;
			padding: 20px;
		}
	}
}
//Formular===========================
.contact-form {
	small {line-height: auto}
}


.form-control {
	border: none;
	color: #000;
	font-style: italic;
	border-bottom: solid 1.5px #dedede;
	border-radius: 0px;
	background: none;
	display:block;
	width: 100%;
	padding: .375rem .75rem;
	font-size: 1rem;
	line-height: 1.5;
}

.form-control:focus {
	background: none;
	color: #000;
	border-color: #f87309;
	box-shadow: none;
}

.form-group {
	position: relative;
	margin-bottom: 2.2rem;
	margin-top: 1rem;
}

.floating-label {
	color:#999;
	font-size:0.9rem;
	font-weight:normal;
	position:absolute;
	pointer-events:none;
	left:5px;
	top:10px;
	transition:0.2s ease all;
	-moz-transition:0.2s ease all;
	-webkit-transition:0.2s ease all;
}

input:focus ~ .floating-label, input:valid ~ .floating-label  {
	top:-25px;
	font-size:14px;
	color: #f87309;
}

textarea:focus ~ .floating-label, textarea:valid ~ .floating-label  {
	top:-25px;
	font-size:14px;
	color: #f87309;
}


/*Toggle Switch */
/* The switch - the box around the slider */
.switch {
	position: relative;
	display: inline-block;
	max-width: 75px;
	height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
			opacity: 0;
		}
			


/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 15px;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}

input:checked + .slider {
	background-color: #f87309;
}

input:focus + .slider {
	box-shadow: 0 0 1px #f87309;
}

input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}

h2.typewriter{
	font-style: italic;
	span {
		color: $color-primary !important;
		font-size: 2rem;
	}
}
