@font-face {
  font-family: 'Aestetico';
  font-style: normal;
  font-weight: 400;
  font-display: auto; /* or block, swap, fallback, optional */
  src: local('Aestetico'),
  url('../fonts/Aestetico-Light.woff') format('woff2'), /* will be preloaded */
  url('../fonts/AAestetico-Light.woff2') format('woff');
  unicode-range: U+000-5FF; /* Latin glyphs */
}

@font-face {
  font-family: 'Aestetico';
  font-style: normal;
  font-weight: 700;
  font-display: auto; /* or block, swap, fallback, optional */
  src: local('Aestetico'),
  url('../fonts/Aestetico-Semibold.woff2') format('woff2'), /* will be preloaded */
  url('../fonts/Aestetico-Semibold.woff') format('woff');
  unicode-range: U+000-5FF; /* Latin glyphs */
}

@font-face {
  font-family: 'Aestetico';
  font-style: normal;
  font-weight: 800;
  font-display: auto; /* or block, swap, fallback, optional */
  src: local('Aestetico'),
  url('../fonts/Aestetico-Bold.woff2') format('woff2'), /* will be preloaded */
  url('../fonts/Aestetico-Bold.woff') format('woff');
  unicode-range: U+000-5FF; /* Latin glyphs */
}

