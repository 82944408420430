/*========================Variables======================*/
$font-stack: 'Aestetico', sans-serif;
$font-stack-body: 'Aestetico', sans-serif;
$font-size-base: 1rem;
$font-size-imp: 1.25rem;

$color-primary: #f05923;
$color-secondary: #f6921d;
$color-tertiary: #283134;
$color-header: #fff;


/////Gradients
$gradient-primary: linear-gradient(135deg,#f05923 1%,#f6921d 100%);
//Mixin
@mixin gradient($property) {
  background: $property; 
}

@mixin list {
  span {
	  color: $color-primary
  }
	&:before {
		display: inline-block;
		content: "";
		@include icon(14);
		background-image: get-icon('chevron-right', $color-primary);
		background-repeat: no-repeat;
		background-size: 1rem 1rem;
		margin-right: 10px;
	}
}

@mixin button ($color) {
  	background: none;
	display: inline-block;
	margin-top: 20px;
	padding: .8125rem; 
	border-left: 2px solid $color; 
	border-bottom: 2px solid $color; 
	color: $color;
	&:after {
		content: "";
		position: absolute;
		height: 100%;
		width: 2px;
		transition-delay: 0;
		transition-duration: .2s;
		background: $color;
		bottom: 0;
		right: 0;
	}
	&:before {
		content: "";
		position: absolute;
		transition-delay: .2s;
		transition-duration: .2s;
		width: 100%;
		height: 2px;
		background: $color;
		top: 0;
		right: 0;
	}
    &:hover {
		color: $color;
		&:after {
			transition-delay: .2s;
			height: 0;
			width: 2px;
		}
		&:before {
			transition-delay: 0s;
			transition-duration: .2s;
			width: 0;
			height: 2px;
		}
		span {
			transition-delay: .3s;
			transform: translateX(8px);
			transition-duration: .2s;
			left:8px;
		}
	}
	span {
		display: inline-block;
		transition-duration: .2s;
		transform: translateX(0);
		font-size: 0.9rem;
		margin-left: 10px;
		left:-20px;
	}
}

@mixin fixed-bg-image ($url) {
  background-position: center;
  background-attachment: fixed; 
	background-image: url($url);
	background-size: cover;
  background-repeat: no-repeat;
  min-height: 300px;
}


@mixin icon ($size) {
	display: inline-block;
	content: "";
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100%;
	height: $size + px;
	width: $size + px;
}

//Media Structure 2019
@mixin responsive ($breakpoint) {
    @if ($breakpoint == phone) {
      @media screen and  (min-width: 576px) { @content };
    } 
    @if ($breakpoint == tablet) {
      @media screen and  (min-width: 768px) { @content };
    } 
    @if ($breakpoint == landscape ) {
      @media screen and  (min-width: 992px) { @content };
    } 
    @if ($breakpoint == desktop) {
      @media screen and  (min-width: 1200px) { @content };
    } 
} 

