//Case Studies
.cs__wrapper {
	max-width: 1140px;
}
.cs-teaser {
	position: relative;
	margin-bottom: 80px;
	overflow: visible;
	/*.cs-overlay {
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 100%;
		//background: rgba($color-tertiary, 0.7);
	}*/
	.cs-inner {
		/*position: relative;;
		width: 100%;
		height: 100%;
		padding: 20px;*/
	}
	.cs-data {
		position: absolute;
		top: 10px;
		font-weight: 800;
		font-size: 1rem;
		text-decoration: underline;
	}
	.cs-desc {
		/*position: absolute;
		bottom: -20px;
		padding: 20px;
		width: 90%;
		left: 0;
		right: 0;
		margin: auto;
		background: #fff;
		box-shadow: 10px 10px 23px -7px rgba(0,0,0,0.52);*/
		transition-duration: .3s;
		margin-top: 25px;
		h3 {
			text-transform: uppercase;
			font-size: 1rem;
			font-weight: 500;
		}
		a {
			color: #000;
		}
		p {
			color: #000;
			display: flex;
			font-size: 1.6rem;
			/*font-weight: 700;*/
			line-height: normal;
			margin-bottom: 25px;
			&:before {
				height: 65px;
				margin-right: 10px;
				width: 7px;
				/*content: "";*/
				background: $color-primary;
				display: flex;
			}
		}
	}
	figure {
		overflow: hidden;
	}
	img {
		transition: transform .6s ease-out;
	}
}

.cs-teaser:hover {
	transition: all .3s ease-out;
	.cs-desc {
		bottom: 0;
	}
	.cs-overlay {
		transition-duration: .3s;
		/*background: rgba($color-primary, 0.8);*/
	}
	img {
		transition: transform .6s ease-out;
		transform: scale(1.1);
	}
}


.briefing {
	border-bottom: solid 1px;
	text-align: center;
	padding-bottom: 20px;
	h2 {
		font-family: 'Poppins', sans-serif;
		font-size: 2rem;
		font-weight: 700;
		color: #cacaca;
	}
	a {
		color: $color-primary;
	}
}

.projectCase {
	.projectCase__fullwidth {
		.projectCase__textbox {
			max-width: 80%;
			padding: 20px;
			margin: auto;
		}
	}
}  

//HAL-Case Study
.hal-computers-video {
	overflow: visible;
	padding: 20px;
	box-shadow: -18px 10px 30px -11px rgba(0,0,0,0.36);
	border: solid 1px #eaeaea;
	margin-bottom: 80px;
}

//ITSMedia-Case Study
.itsmedia_background {
	background: url('/files/images/Projekte/itsmedia_background.jpg') no-repeat;
	background-size: cover;
}

//Liebls Case Study
.liebls_background {
	background:#071f2b;
}


.case-new .briefing {
	text-align: left;
	border-bottom: none;
	padding-bottom: 0;
	font-weight: 700;
	font-size: 1.2rem;
}
.case-new .overview p {
	margin-bottom: 5px;
}
.case__muehlbauer__bg {
	background: url(/files/images/Projekte/cs_muehlbauer-steuerberater_background.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.case__osteopathie__bg {
	background: url(/files/images/Projekte/cs_osteopathie-breu_background.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}
.case__video {
	box-shadow: #161c24 0 0 80px 0;
	border: solid 4px #d0d0d0;
	border-radius: 10px;
}

.case__video figure {
	display: flex;
}
.header--sub .inner h1 {
	font-size: 2.2rem;
	@media screen and (min-width: 576px) {
		font-size: 2.5rem;
	}
}

blockquote p {
	line-height: 1.8rem;
	max-width: 100%;
	margin-left: 36px;
	font-size: 1.2rem;
	font-weight: bold;
	@media screen and (min-width: 576px) {
		line-height: 2.2rem;
		max-width: 80%;
		margin-left: 70px;
		font-size: 1.8rem;
	}
}

blockquote:before {
	height: 30px;
	width: 30px;
	@media screen and (min-width: 576px) {
		height: 50px;
		width: 50px;
	}
}

//#########Google Rezensionen / Bewertungen
.mod_recommendationlist {
	display: flex;
	padding: 30px;
	background: rgba($color-tertiary, .8);
	align-items: center;
	color: #fff;
	border-radius: 40px;
	.quote {
		position: relative;
		padding-left: 10px;
		@include responsive(phone) {
			padding-left: 70px;
		}
		&:before {
			@include icon(110);
			background-image: get-icon('quotations', #f3f3f3);
			position: absolute;
			left: -10px;
			top: -30px;
			opacity: 0.6;
		}
		p {
			line-height: 1.5;
		}
		h4, .author {
			font-weight: bold;
		}
		figure {
			margin-right: 15px;
		}
	}
}