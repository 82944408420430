/* --------------------------
Media-Queries
--------------------------- */
@media only screen and (min-width: 992px) {

::-moz-selection { /* Code for Firefox */
    color: #fff;
    background: $color-secondary;
}

::selection {
    color: #fff;
    background: $color-secondary;
}

/* width */
::-webkit-scrollbar {
    width: 15px;
    height: 20px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $color-primary;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: darken($color-primary, 0.9);
}

}



//===================================(min-width: 992px)
@media screen and (min-width: 992px) {
	.id-mobile-menu {
		 display: none;
  }
	.id-main-navigation {
		display: block;
	}
  .gb-80 {
    margin-bottom: 80px;
  }
  .gt-80 {
    margin-top: 80px;
  }
  .header--start {
    background-size: 500px auto;
  }
}




//===================================(min-width: 576px)
@media screen and (min-width: 576px) {
  .shrink .id-navigation {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .id-navigation {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  
  .id-featured-content {
    .text-container {
      padding: 30px 60px;
    }
  }
  .nl-sub-box {
    height: 210px;
    .container {
      .sub-wrapper {
        overflow: visible;
        max-width: 80%;
        margin: auto;
        .formbody {
          flex-wrap: nowrap;
          input {
            width: 75%;
            margin-bottom: 0; 
          }
        }
      }
    }
  }
  .nl-sub-box {
    display: block;
  }
  .header--start, .header--sub {
    min-height: 550px;
  }
}
//===================================(min-width: 768px)
@media screen and (min-width: 768px) {
	.header--start, .header--sub  {
		.inner p {
      font-size: 1.2rem;
      line-height: 2rem;
    }
	}
}

//===================================(min-width: 768px)
@media screen and (min-width: 992px) {
	.header--start {
		//background: url(/files/images/header-color-wave.svg) no-repeat right top,linear-gradient(0deg,#eaeaea 0,#f5f5f5 46%,#fff 100%);
    min-height: 500px;
	}
}

//===================================(min-width: 1200px)
@media screen and (min-width: 1200px) {
  .header--start {
    background-size: 100%; 
  }
  #services h2{
    font-size: 50px;
  }
  .header--start .container {
    transform: translateY(-45%)
  }
}