html {
	font-size: 18px;
}

body {
	font-family: $font-stack-body;
	font-weight: 400;
	font-size: 1rem;
}

h1 {
	color: $color-tertiary;
	line-height: 1.2;
}

h1, h2, h3 {
  	font-family: $font-stack;
	font-weight: 600;
	line-height: 1.2; 
}

h4 {
	font-size: 1.4rem;
	font-weight: 500;
	margin-top: 0;
}


a {
	color: $color-primary;
	text-decoration: none;
	z-index: 0;
	&:hover {
	color: darken($color-primary, 20);
	}
}

small {
	font-size: 0.8rem;
	line-height: normal;
}

figure {
	margin: 0;
	figcaption {
    padding-top:20px; 
		font-size: 0.9rem; 
		color: #9a9a9a;
	}
}

strong {
	font-weight:700;
}


p {
	line-height: 1.5rem;
}

.block {
	&--visible {
		overflow: visible;
	}
}

.list {
	li {@include list};
	p {margin-bottom: 0}
}

blockquote{
	position: relative;
	&:before {
		@include icon(50);
		background-image: get-icon('quotations', $color-tertiary);
		position: absolute;
		left: 0;
		top: 0;
	}
	p {
		line-height: 2.2rem;
		max-width: 80%;
		margin-left: 70px;
		font-size: 1.8rem;
	}
	small {
		display: block;
		margin-top: 10px;
	}
}

.w-100 {
	width: 100%;
	img {
		width: 100%;
	}
}

.text-primary {
	color: $color-primary !important;
}

.text-center {
	text-align: center;
	margin: auto;
}

.text-dark {
	color: #000;
}

.text-white {
	color: #fff;
}

.highlight {
	h2 {
		&::after {
			margin-top: 10px;
			content:"";
			width: 60px;
			height: 5px;
			display: block;
			background-color: $color-primary;
		}
	}
}

//gaps
.gap-10 {
	margin-top: 10%;
}

.gap-20 {
	margin-top: 20%;
}
.gap-30 {
	margin-top: 30%;
}
.gap-40 {
	margin-top: 40%;
}
.gap-50 {
	margin-top: 50%;
}
.gap-60 {
	margin-top: 60%;
}
.gap-70 {
	margin-top: 70%;
}
.gt-80 {
	margin-top: 40px;
}

.gb-80 {
	margin-bottom: 40px;
}


//Neu
.py-default {
	padding-top: 80px;
	padding-bottom: 80px;
}
//Background Colours
.bg-white {
	background-color: #fff;
}

.bg-grey {
	background-color: #edf1f2;
	padding: 20px;
}

.bg-dark {
	background-color: $color-tertiary;
}
/*========================Header======================*/



/*========================Main-Content======================*/
/*-------Buttons*/

button {
	cursor: pointer;
	border: none;
}

.btn-lg {
	border-radius: 25px !important;
	font-size: $font-size-imp;
	&:hover {
		box-shadow: none;
		-webkit-box-shadow: none;
	}
}

.shadowed {
    box-shadow: 8px 5px 20px 1px rgba(0,0,0,0.15);
    cursor: pointer;
		&:hover {
			box-shadow: none;
		}
}

.btn--submit {
	background-color: $color-primary;
	color: #fff;
	padding: .8125rem;
	transition: background-color .3s;
	&:hover {
		color: #fff;
		background-color: lighten($color-primary, 5)
	}
}


.btn--primary {
	@include button($color-primary);
}

.btn--secondary {
	@include button(#fff); 
}

.image {
	&--grayscale {
		filter: grayscale(1);
		opacity: 0.5;
		transition: all .3s;
		&:hover {
			filter: none;
			opacity: 1;
		}
	}
	&__fullwidth {
		@include responsive (landscape) {
			position: absolute;
			width: 45%;
			max-width: 1000px;
			padding: 0;
		}
		position: relative;
		width: 100%;
		height: 100%;
		&--left {
			left: 0;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: right;

			}
		}
		&--right {
			right: 0;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: left;

			}
		}
		.image_container {
			height: 100%;
		}
	}
}

.container {
	max-width: 1300px;
}

.container-fluid {
	position: relative;
}

/*------Sections*/

//Skills-Containers
#rocket-container,
#cart-container,
#chart-container {
	padding: 20px;
	z-index: 10;
 &:hover {
	 i {
		 transform: translateX(5px);
		 transition-duration: .3s;
	 }
 }
}


.team-container {
	h2 {
		font-size: 1.4rem;
		font-weight: 600;
		text-transform: uppercase;
		margin-bottom: 0;
	}
	.subheading {
		margin-top: 5px;
		color: #cacaca;
		font-weight: 500;
	}
	a {
		color: #cacaca;
		transition-property: color;
		transition-duration: .3s;
		&:hover {
			color: $color-primary;
		}
	}
	i {
		font-size: 2rem;
		margin-right: 15px;
	}
}



.id-customer {
	background-color: $color-tertiary;
		.inner {
			padding-top: 15px;
		}
}




//Call to Action ------Sections
.id-cta {
	margin: 80px 0 0 0;
	background-image: linear-gradient(to left top, #f05923, #f26c24, #f37d27, #f48d2d, #f49c36);
	color: #fff;
	.inner {
		padding: 80px 0;
		margin: auto;
	}
}


#latest-projects {
	.row {
		max-width: 1300px;
		margin: auto;
		padding-top: 40px;
	}
	.cs {
		&:last-child {
			@include responsive (tablet) {
				display:block;
			}
			display: none;
		}
	}
}


#about {
	@include responsive (tablet) {
		padding-top: 120px;
		padding-bottom: 120px;
	}
	padding-top: 80px;
	padding-bottom: 80px;
	background: #edf1f2;
	h1, h3 {
		margin: 0;
	}
	h2 {
		@include responsive(tablet) {
			font-size: 2.5rem;
		}
		color: $color-tertiary;
		margin-top: 0;
		font-size: 1.4rem;
	}
	h3 {
		font-size: 0.9rem;
    	color: #eb5b27;
	}
}

.services {
	.services__service {
		display: flex;
		align-items: center;
		background: url("https://www.toptal.com/designers/subtlepatterns/patterns/papyrus.png");
		padding: 15px;
		margin-bottom: 20px;
		transition: all .3s ease;
		box-shadow: 8px 5px 20px 1px rgba(0,0,0,0.15);
		&:hover {
			.services__image {
				fill: $color-primary;
				transition: all .3s ease;
			}
		}
	}
	a {
		color: #000;
	}
	.services__image {
		max-width: 50px;
		margin-right: 20px;
	}

}

#customers {
	padding-top: 80px;
	padding-bottom: 80px;
	background: $color-tertiary;
	h2 {
		font-size: 1.5rem;
		margin-bottom: 60px;
		color: #fff;
		text-align: center;
		text-transform: uppercase;
	}
}

.customers-wrapper {
	max-width: 920px;
	margin: auto;
	display: flex;
	align-items: center;
	&__showmore {
		margin-top: 40px;
		font-size: 0.9rem;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		align-items: center;
		&--active {
			i {
				transform: rotate(180deg);
			}
		}
	}
	&--full {
		max-height: 0px;
		transition: max-height .8s ease;
		overflow: hidden;
		justify-content: center;
	}
	.ce_image {
		margin: 10px 0;
		text-align: center;
		opacity: 0.8;
	}
}

.ce_accordion {
	.toggler {
		font-weight: 700;
		cursor: pointer;
		outline: none;
		&.active span:before {
			transform: rotate(90deg);
		}
	}
	p {
		font-size: 0.8rem;
		line-height: 1.5em;
		padding-left: 20px;
	}
}

#project-highlight {
	background-position: right;
	background-repeat: no-repeat;
	background-size: contain;
	padding-top: 80px;
	h2 {
		color: $color-tertiary;
		margin-top: 0;
		font-size: 2rem;
	}
	.project-highlight__textcontent {
		font-size: 1.2rem;
		line-height: normal;
		flex-direction: column;
		justify-content: center;
		display: flex;
	}
}

.headline {
	&--label {
		color: $color-tertiary;
		display: flex;
		align-items: center;
		font-size: 0.9rem;
		margin-bottom: 15px;
		&:after {
			content: "";
			height: 1px;
			width: 20%;
			background: $color-tertiary;
			display: inline-block;
			margin-left: 20px;
		}
	}
}

#blog {
	padding-top: 40px;
	padding-bottom: 40px;
	h2 {
		font-size: 2.5rem;
	}
	.info {
		display: flex;
		font-size: 0.9rem;
		margin-bottom: 0;
		i {
			display: flex;
			align-items: center;
		}
	}
}

.newsreader {
	.ce_text {
		max-width: 700px;
		margin: auto;
	}
	h3 {
		font-size: 1.4rem;
		font-weight: 500;
		color: $color-primary;
	}
	ol {
		display: block;
		list-style-type: decimal;
		margin-block-start: 1em;
		margin-block-end: 1em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		padding-inline-start: 40px;
	}

	li {
		margin-bottom: 1rem;
	}
	p, ul {
		margin-bottom: 1.5rem;
	}


}


input:focus {
	box-shadow: none;
	outline: none;
}

input:-webkit-autofill {
	-webkit-text-fill-color: none !important;
}

//Fixed Background image
.fixed-bg-image {
	@include fixed-bg-image('/files/images/Blog/newsletter-smartphone.jpg'); 
}

.h-square {
	min-height: 30vw;
}

.mh-700 {
	@include responsive(landscape) {
		min-height: 700px;
	}
}

.object-fit-image {
	object-fit: cover;
}

//Swiper
.swiper-wrapper {
	display: flex;
	align-items: center;
}

.swiper-button-next {
	@include icon(50);
	background-image: get-icon('chevron-compact-right')
}

.swiper-button-prev {
	visibility: hidden;
	@include icon(50);
	background-image: get-icon('chevron-compact-left')
}

.swiper-slide {
	text-align: center;
}






