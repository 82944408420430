/*================================SVG-Animations===========================*/
svg {
  width: 100%;
}
@keyframes MoveUpDown {
  0%, 100% {
    bottom: 0;
  }
  50% {
    bottom: 100px;
  }
}
