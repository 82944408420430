#id-navigation-wrapper {
	position: fixed;
  width: 100%;
  z-index: 999;
	text-transform: uppercase;
	.container {
		position: relative;
	}
}

.id-main-navigation {
	display: none;
  float: right;
  font-family: $font-stack;
	font-size: 16px;
				ul{
				margin: 0px;
				float: right;
					ul.level_2 {
						display: none;
						position: absolute;
						width: 400px;
						background-color: $color-secondary;
						li {
							width: 100%;
							&:hover {
								background-color: darken($color-secondary, 10);
							}

								a {
									width: 100%;
									padding: 10px 20px;
									display: block;
									text-decoration: none;


								}
						}
						li.active {
							padding: 10px 20px;
							background-color: #d77809;
							color: $color-primary;
						}


					}
							li {
							display: inline-block;
							transition-duration: .3s;
							position: relative;
								 a {
                  color: #000;
									padding: 25px 15px;
									display: block;
									text-decoration: none;
									transition-duration: .2s;
                  span {
                    position: relative;
										font-weight: 600;
                  }
										}
									&:hover a{
										color: $color-primary;
										ul.level_2{
											display: inherit;
											transition-property: display;
											transition-duration: .2s;
										}
									}
									strong.active {
										color: $color-primary;
										padding: 25px 20px;
										background: none;
										font-weight: 600;

									}
								 }
							}
}




.shrink {
  background-color: #fff !important;
  box-shadow: 0 8px 24px rgba(0,0,0,.13);
		.id-main-navigation ul li a {
			padding: 15px 10px !important;
			transition-duration: .3s;
		}
		img {
			//width: 190px !important;
			//transition-duration: .3s;
			//width: 60px;
		}
		.id-mobile-menu  {
			padding-top: 5px !important;
			padding-right: 15px !important;
			color: #fff; 
		}
			.id-navigation {
				padding-top: 10px;
				padding-bottom: 10px;
		}
		.id-mobile-menu {
			&:hover {
				color: $color-primary;
			}
		}
		.cd-nav-trigger {
			transform: translateY(-10px);
			transition-duration: .3s;
		}
}

.id-navigation {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.id-mobile-menu {
	display: flex;
	justify-content: flex-end;
	align-items: center;
  font-size: 2.2rem;
	color: #000;
		&:hover {
			color: #273033;
		}
	}


.id-logo {
	float: left;
	width: 250px;
	height: auto;
	transition-duration: .3s;
}

//Blog news amount
/*
ul.level_1 li:nth-child(4):hover::after {
height: 20px;
font-size: 0.8rem;
width: 20px;
text-align: center;
color: #fff;
border-radius: 50%;
background: orange;
content: "3";
right: -5px;
top: 10px;
position: absolute;
}
*/


.id-nav-mobile {
	display: block;
}


.cd-nav-trigger {
  top: 25px;
  right: 0;
  height: 44px;
  width: 44px;
  z-index: 5;
  /* image replacement */
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
	display: block;
	cursor: pointer;
	position: absolute;
}

@media screen and (min-width: 992px) {
	.cd-nav-trigger {
		display: none;
	}
	.id-nav-mobile {
		display: none;
	}
}
.cd-nav-trigger .cd-icon {
  /* icon created in CSS */
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: inline-block;
  width: 18px;
  height: 3px;
  background-color: #fff;
  z-index: 10;
}
.cd-nav-trigger .cd-icon::before, .cd-nav-trigger .cd-icon:after {
  /* upper and lower lines of the menu icon */
  position: absolute;
	content: "";
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /* apply transition to transform property */
  -webkit-transition: -webkit-transform .3s;
  -moz-transition: -moz-transform .3s;
  transition: transform .3s;
}
.cd-nav-trigger .cd-icon::before {
  -webkit-transform: translateY(-6px) rotate(0deg);
  -moz-transform: translateY(-6px) rotate(0deg);
  -ms-transform: translateY(-6px) rotate(0deg);
  -o-transform: translateY(-6px) rotate(0deg);
  transform: translateY(-6px) rotate(0deg);
}
.cd-nav-trigger .cd-icon::after {
  -webkit-transform: translateY(6px) rotate(0deg);
  -moz-transform: translateY(6px) rotate(0deg);
  -ms-transform: translateY(6px) rotate(0deg);
  -o-transform: translateY(6px) rotate(0deg);
  transform: translateY(6px) rotate(0deg);
}
.cd-nav-trigger::before, .cd-nav-trigger::after {
  /* 2 rounded colored backgrounds for the menu icon */
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  transition-property: transform;
}
.cd-nav-trigger::before {
	content: "";
  background-color: $color-primary;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-delay: 0.4s;
  -moz-transition-delay: 0.4s;
  transition-delay: 0.4s;
}
.cd-nav-trigger::after {
  background-color: #ffb441;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition-duration: 0s;
  -moz-transition-duration: 0s;
  transition-duration: 0s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
}
.cd-nav-trigger.close-nav::before {
  /* user clicks on the .cd-nav-trigger element - 1st rounded background disappears */
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
.cd-nav-trigger.close-nav::after {
  /* user clicks on the .cd-nav-trigger element - 2nd rounded background appears */
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-delay: 0.4s;
  -moz-transition-delay: 0.4s;
  transition-delay: 0.4s;
}
.cd-nav-trigger.close-nav .cd-icon {
  /* user clicks on the .cd-nav-trigger element - transform the icon */
  background-color: rgba(255, 255, 255, 0);
}
.cd-nav-trigger.close-nav .cd-icon::before, .cd-nav-trigger.close-nav .cd-icon::after {
  background-color: white;
}
.cd-nav-trigger.close-nav .cd-icon::before {
  -webkit-transform: translateY(0) rotate(45deg);
  -moz-transform: translateY(0) rotate(45deg);
  -ms-transform: translateY(0) rotate(45deg);
  -o-transform: translateY(0) rotate(45deg);
  transform: translateY(0) rotate(45deg);
}
.cd-nav-trigger.close-nav .cd-icon::after {
  -webkit-transform: translateY(0) rotate(-45deg);
  -moz-transform: translateY(0) rotate(-45deg);
  -ms-transform: translateY(0) rotate(-45deg);
  -o-transform: translateY(0) rotate(-45deg);
  transform: translateY(0) rotate(-45deg);
}

.cd-primary-nav {
  /* by default it's hidden */
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 80px 5%;
  z-index: 3;
  background-color: #091d23;
  overflow: auto;
  /* this fixes the buggy scrolling on webkit browsers - mobile devices only - when overflow property is applied */
  -webkit-overflow-scrolling: touch;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.cd-primary-nav li {
  margin: 5px auto;
  text-align: center;
  list-style: none;
  font-size: 3rem;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  width: 100%;
	.active {
		color: $color-primary;
	}
}
.cd-primary-nav a {
	color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 0.2s;
  -moz-transition: color 0.2s;
  transition: color 0.2s;
	&:hover {
		color: $color-primary;
	}
}
.no-touch .cd-primary-nav a:hover {
  color: #ffffff;
}
.cd-primary-nav.fade-in {
  /* navigation visible at the end of the circle animation */
  visibility: visible;
  opacity: 1;
}


.cd-overlay-nav, .cd-overlay-content {
  /* containers of the 2 main rounded backgrounds - these containers are used to position the rounded bgs behind the menu icon */
  position: fixed;
  top: 18px;
  right: 5%;
  height: 4px;
  width: 4px;
  -webkit-transform: translateX(-20px) translateY(20px);
  -moz-transform: translateX(-20px) translateY(20px);
  -ms-transform: translateX(-20px) translateY(20px);
  -o-transform: translateX(-20px) translateY(20px);
  transform: translateX(-20px) translateY(20px);
}
.cd-overlay-nav span, .cd-overlay-content span {
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
.cd-overlay-nav.is-hidden, .cd-overlay-content.is-hidden {
  /* background fades out at the end of the animation */
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity .3s 0s, visibility 0s .3s;
  -moz-transition: opacity .3s 0s, visibility 0s .3s;
  transition: opacity .3s 0s, visibility 0s .3s;
}

.cd-overlay-nav {
  /* main rounded colored bg 1 */
  z-index: 2;
}
.cd-overlay-nav span {
  background-color: #091d23;
}

.cd-overlay-content {
  /* main rounded colored bg 2 */
  z-index: 4;
}
.cd-overlay-content span {
  background-color: #ffb441;
}

.cd-content {
  /* just some dummy content */
  padding: 80px 0;
  width: 90%;
  max-width: 768px;
  margin: 0 auto;
  z-index: 1;
}
.cd-content .cd-intro {
  height: 200px;
  padding-top: 4.6em;
}
.cd-content h1 {
  text-align: center;
  font-size: 20px;
  font-size: 1.25rem;
}
.cd-content p {
  line-height: 1.5;
  color: #a76500;
}
@media only screen and (min-width: 768px) {
  .cd-content .cd-intro {
    height: 250px;
    padding-top: 6em;
  }
  .cd-content h1 {
    font-size: 28px;
    font-size: 1.75rem;
  }
  .cd-content p {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.8;
  }
}
