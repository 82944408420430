/*!
 * Bootstrap Reboot v4.5.3 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0056b3;
  text-decoration: none;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}


@font-face {
  font-family: "Aestetico";
  font-style: normal;
  font-weight: 400;
  font-display: auto; /* or block, swap, fallback, optional */
  src: local("Aestetico"), url("../fonts/Aestetico-Light.woff") format("woff2"), url("../fonts/AAestetico-Light.woff2") format("woff");
  unicode-range: U+000-5FF; /* Latin glyphs */
}
@font-face {
  font-family: "Aestetico";
  font-style: normal;
  font-weight: 700;
  font-display: auto; /* or block, swap, fallback, optional */
  src: local("Aestetico"), url("../fonts/Aestetico-Semibold.woff2") format("woff2"), url("../fonts/Aestetico-Semibold.woff") format("woff");
  unicode-range: U+000-5FF; /* Latin glyphs */
}
@font-face {
  font-family: "Aestetico";
  font-style: normal;
  font-weight: 800;
  font-display: auto; /* or block, swap, fallback, optional */
  src: local("Aestetico"), url("../fonts/Aestetico-Bold.woff2") format("woff2"), url("../fonts/Aestetico-Bold.woff") format("woff");
  unicode-range: U+000-5FF; /* Latin glyphs */
}
/*========================Variables======================*/
/*================================SVG-Animations===========================*/
svg {
  width: 100%;
}

@keyframes MoveUpDown {
  0%, 100% {
    bottom: 0;
  }
  50% {
    bottom: 100px;
  }
}
.cookiebar {
  background: rgba(40, 49, 52, 0.9);
  font-size: 0.9rem;
  text-align: left;
  max-width: 350px;
  line-height: normal;
  bottom: 20px;
  left: auto;
  right: 20px;
  padding: 20px;
  justify-content: left;
}
.cookiebar .cookiebar__button {
  background: none;
  border: solid 1px #fff;
  margin-top: 10px;
  color: #fff;
  display: block;
}
.cookiebar .cookiebar__button:hover {
  background: none;
  color: #fff;
}
.cookiebar a {
  display: block;
}
.cookiebar a:hover {
  text-decoration: underline;
}

.cookiebar__message, .cookiebar__text {
  margin: 0;
}

/*!
 * Bootstrap Grid v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.row-cols-4 > * {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-sm-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-md-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-md-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-md-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-lg-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-xl-4 > * {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

@font-face {
  font-family: "bootstrap-icons";
  src: url("../fonts/bootstrap-icons.woff?4601c71fb26c9277391ec80789bfde9c") format("woff"), url("../fonts/bootstrap-icons.woff2?4601c71fb26c9277391ec80789bfde9c") format("woff2");
}
[class^=bi-]::before,
[class*=" bi-"]::before {
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bi-alarm-fill::before {
  content: "\f101";
}

.bi-alarm::before {
  content: "\f102";
}

.bi-align-bottom::before {
  content: "\f103";
}

.bi-align-center::before {
  content: "\f104";
}

.bi-align-end::before {
  content: "\f105";
}

.bi-align-middle::before {
  content: "\f106";
}

.bi-align-start::before {
  content: "\f107";
}

.bi-align-top::before {
  content: "\f108";
}

.bi-alt::before {
  content: "\f109";
}

.bi-app-indicator::before {
  content: "\f10a";
}

.bi-app::before {
  content: "\f10b";
}

.bi-archive-fill::before {
  content: "\f10c";
}

.bi-archive::before {
  content: "\f10d";
}

.bi-arrow-90deg-down::before {
  content: "\f10e";
}

.bi-arrow-90deg-left::before {
  content: "\f10f";
}

.bi-arrow-90deg-right::before {
  content: "\f110";
}

.bi-arrow-90deg-up::before {
  content: "\f111";
}

.bi-arrow-bar-down::before {
  content: "\f112";
}

.bi-arrow-bar-left::before {
  content: "\f113";
}

.bi-arrow-bar-right::before {
  content: "\f114";
}

.bi-arrow-bar-up::before {
  content: "\f115";
}

.bi-arrow-clockwise::before {
  content: "\f116";
}

.bi-arrow-counterclockwise::before {
  content: "\f117";
}

.bi-arrow-down-circle-fill::before {
  content: "\f118";
}

.bi-arrow-down-circle::before {
  content: "\f119";
}

.bi-arrow-down-left-circle-fill::before {
  content: "\f11a";
}

.bi-arrow-down-left-circle::before {
  content: "\f11b";
}

.bi-arrow-down-left-square-fill::before {
  content: "\f11c";
}

.bi-arrow-down-left-square::before {
  content: "\f11d";
}

.bi-arrow-down-left::before {
  content: "\f11e";
}

.bi-arrow-down-right-circle-fill::before {
  content: "\f11f";
}

.bi-arrow-down-right-circle::before {
  content: "\f120";
}

.bi-arrow-down-right-square-fill::before {
  content: "\f121";
}

.bi-arrow-down-right-square::before {
  content: "\f122";
}

.bi-arrow-down-right::before {
  content: "\f123";
}

.bi-arrow-down-short::before {
  content: "\f124";
}

.bi-arrow-down-square-fill::before {
  content: "\f125";
}

.bi-arrow-down-square::before {
  content: "\f126";
}

.bi-arrow-down-up::before {
  content: "\f127";
}

.bi-arrow-down::before {
  content: "\f128";
}

.bi-arrow-left-circle-fill::before {
  content: "\f129";
}

.bi-arrow-left-circle::before {
  content: "\f12a";
}

.bi-arrow-left-right::before {
  content: "\f12b";
}

.bi-arrow-left-short::before {
  content: "\f12c";
}

.bi-arrow-left-square-fill::before {
  content: "\f12d";
}

.bi-arrow-left-square::before {
  content: "\f12e";
}

.bi-arrow-left::before {
  content: "\f12f";
}

.bi-arrow-repeat::before {
  content: "\f130";
}

.bi-arrow-return-left::before {
  content: "\f131";
}

.bi-arrow-return-right::before {
  content: "\f132";
}

.bi-arrow-right-circle-fill::before {
  content: "\f133";
}

.bi-arrow-right-circle::before {
  content: "\f134";
}

.bi-arrow-right-short::before {
  content: "\f135";
}

.bi-arrow-right-square-fill::before {
  content: "\f136";
}

.bi-arrow-right-square::before {
  content: "\f137";
}

.bi-arrow-right::before {
  content: "\f138";
}

.bi-arrow-up-circle-fill::before {
  content: "\f139";
}

.bi-arrow-up-circle::before {
  content: "\f13a";
}

.bi-arrow-up-left-circle-fill::before {
  content: "\f13b";
}

.bi-arrow-up-left-circle::before {
  content: "\f13c";
}

.bi-arrow-up-left-square-fill::before {
  content: "\f13d";
}

.bi-arrow-up-left-square::before {
  content: "\f13e";
}

.bi-arrow-up-left::before {
  content: "\f13f";
}

.bi-arrow-up-right-circle-fill::before {
  content: "\f140";
}

.bi-arrow-up-right-circle::before {
  content: "\f141";
}

.bi-arrow-up-right-square-fill::before {
  content: "\f142";
}

.bi-arrow-up-right-square::before {
  content: "\f143";
}

.bi-arrow-up-right::before {
  content: "\f144";
}

.bi-arrow-up-short::before {
  content: "\f145";
}

.bi-arrow-up-square-fill::before {
  content: "\f146";
}

.bi-arrow-up-square::before {
  content: "\f147";
}

.bi-arrow-up::before {
  content: "\f148";
}

.bi-arrows-angle-contract::before {
  content: "\f149";
}

.bi-arrows-angle-expand::before {
  content: "\f14a";
}

.bi-arrows-collapse::before {
  content: "\f14b";
}

.bi-arrows-expand::before {
  content: "\f14c";
}

.bi-arrows-fullscreen::before {
  content: "\f14d";
}

.bi-arrows-move::before {
  content: "\f14e";
}

.bi-aspect-ratio-fill::before {
  content: "\f14f";
}

.bi-aspect-ratio::before {
  content: "\f150";
}

.bi-asterisk::before {
  content: "\f151";
}

.bi-at::before {
  content: "\f152";
}

.bi-award-fill::before {
  content: "\f153";
}

.bi-award::before {
  content: "\f154";
}

.bi-back::before {
  content: "\f155";
}

.bi-backspace-fill::before {
  content: "\f156";
}

.bi-backspace-reverse-fill::before {
  content: "\f157";
}

.bi-backspace-reverse::before {
  content: "\f158";
}

.bi-backspace::before {
  content: "\f159";
}

.bi-badge-3d-fill::before {
  content: "\f15a";
}

.bi-badge-3d::before {
  content: "\f15b";
}

.bi-badge-4k-fill::before {
  content: "\f15c";
}

.bi-badge-4k::before {
  content: "\f15d";
}

.bi-badge-8k-fill::before {
  content: "\f15e";
}

.bi-badge-8k::before {
  content: "\f15f";
}

.bi-badge-ad-fill::before {
  content: "\f160";
}

.bi-badge-ad::before {
  content: "\f161";
}

.bi-badge-ar-fill::before {
  content: "\f162";
}

.bi-badge-ar::before {
  content: "\f163";
}

.bi-badge-cc-fill::before {
  content: "\f164";
}

.bi-badge-cc::before {
  content: "\f165";
}

.bi-badge-hd-fill::before {
  content: "\f166";
}

.bi-badge-hd::before {
  content: "\f167";
}

.bi-badge-tm-fill::before {
  content: "\f168";
}

.bi-badge-tm::before {
  content: "\f169";
}

.bi-badge-vo-fill::before {
  content: "\f16a";
}

.bi-badge-vo::before {
  content: "\f16b";
}

.bi-badge-vr-fill::before {
  content: "\f16c";
}

.bi-badge-vr::before {
  content: "\f16d";
}

.bi-badge-wc-fill::before {
  content: "\f16e";
}

.bi-badge-wc::before {
  content: "\f16f";
}

.bi-bag-check-fill::before {
  content: "\f170";
}

.bi-bag-check::before {
  content: "\f171";
}

.bi-bag-dash-fill::before {
  content: "\f172";
}

.bi-bag-dash::before {
  content: "\f173";
}

.bi-bag-fill::before {
  content: "\f174";
}

.bi-bag-plus-fill::before {
  content: "\f175";
}

.bi-bag-plus::before {
  content: "\f176";
}

.bi-bag-x-fill::before {
  content: "\f177";
}

.bi-bag-x::before {
  content: "\f178";
}

.bi-bag::before {
  content: "\f179";
}

.bi-bar-chart-fill::before {
  content: "\f17a";
}

.bi-bar-chart-line-fill::before {
  content: "\f17b";
}

.bi-bar-chart-line::before {
  content: "\f17c";
}

.bi-bar-chart-steps::before {
  content: "\f17d";
}

.bi-bar-chart::before {
  content: "\f17e";
}

.bi-basket-fill::before {
  content: "\f17f";
}

.bi-basket::before {
  content: "\f180";
}

.bi-basket2-fill::before {
  content: "\f181";
}

.bi-basket2::before {
  content: "\f182";
}

.bi-basket3-fill::before {
  content: "\f183";
}

.bi-basket3::before {
  content: "\f184";
}

.bi-battery-charging::before {
  content: "\f185";
}

.bi-battery-full::before {
  content: "\f186";
}

.bi-battery-half::before {
  content: "\f187";
}

.bi-battery::before {
  content: "\f188";
}

.bi-bell-fill::before {
  content: "\f189";
}

.bi-bell::before {
  content: "\f18a";
}

.bi-bezier::before {
  content: "\f18b";
}

.bi-bezier2::before {
  content: "\f18c";
}

.bi-bicycle::before {
  content: "\f18d";
}

.bi-binoculars-fill::before {
  content: "\f18e";
}

.bi-binoculars::before {
  content: "\f18f";
}

.bi-blockquote-left::before {
  content: "\f190";
}

.bi-blockquote-right::before {
  content: "\f191";
}

.bi-book-fill::before {
  content: "\f192";
}

.bi-book-half::before {
  content: "\f193";
}

.bi-book::before {
  content: "\f194";
}

.bi-bookmark-check-fill::before {
  content: "\f195";
}

.bi-bookmark-check::before {
  content: "\f196";
}

.bi-bookmark-dash-fill::before {
  content: "\f197";
}

.bi-bookmark-dash::before {
  content: "\f198";
}

.bi-bookmark-fill::before {
  content: "\f199";
}

.bi-bookmark-heart-fill::before {
  content: "\f19a";
}

.bi-bookmark-heart::before {
  content: "\f19b";
}

.bi-bookmark-plus-fill::before {
  content: "\f19c";
}

.bi-bookmark-plus::before {
  content: "\f19d";
}

.bi-bookmark-star-fill::before {
  content: "\f19e";
}

.bi-bookmark-star::before {
  content: "\f19f";
}

.bi-bookmark-x-fill::before {
  content: "\f1a0";
}

.bi-bookmark-x::before {
  content: "\f1a1";
}

.bi-bookmark::before {
  content: "\f1a2";
}

.bi-bookmarks-fill::before {
  content: "\f1a3";
}

.bi-bookmarks::before {
  content: "\f1a4";
}

.bi-bookshelf::before {
  content: "\f1a5";
}

.bi-bootstrap-fill::before {
  content: "\f1a6";
}

.bi-bootstrap-reboot::before {
  content: "\f1a7";
}

.bi-bootstrap::before {
  content: "\f1a8";
}

.bi-border-all::before {
  content: "\f1a9";
}

.bi-border-bottom::before {
  content: "\f1aa";
}

.bi-border-center::before {
  content: "\f1ab";
}

.bi-border-inner::before {
  content: "\f1ac";
}

.bi-border-left::before {
  content: "\f1ad";
}

.bi-border-middle::before {
  content: "\f1ae";
}

.bi-border-outer::before {
  content: "\f1af";
}

.bi-border-right::before {
  content: "\f1b0";
}

.bi-border-style::before {
  content: "\f1b1";
}

.bi-border-top::before {
  content: "\f1b2";
}

.bi-border-width::before {
  content: "\f1b3";
}

.bi-border::before {
  content: "\f1b4";
}

.bi-bounding-box-circles::before {
  content: "\f1b5";
}

.bi-bounding-box::before {
  content: "\f1b6";
}

.bi-box-arrow-down-left::before {
  content: "\f1b7";
}

.bi-box-arrow-down-right::before {
  content: "\f1b8";
}

.bi-box-arrow-down::before {
  content: "\f1b9";
}

.bi-box-arrow-in-down-left::before {
  content: "\f1ba";
}

.bi-box-arrow-in-down-right::before {
  content: "\f1bb";
}

.bi-box-arrow-in-down::before {
  content: "\f1bc";
}

.bi-box-arrow-in-left::before {
  content: "\f1bd";
}

.bi-box-arrow-in-right::before {
  content: "\f1be";
}

.bi-box-arrow-in-up-left::before {
  content: "\f1bf";
}

.bi-box-arrow-in-up-right::before {
  content: "\f1c0";
}

.bi-box-arrow-in-up::before {
  content: "\f1c1";
}

.bi-box-arrow-left::before {
  content: "\f1c2";
}

.bi-box-arrow-right::before {
  content: "\f1c3";
}

.bi-box-arrow-up-left::before {
  content: "\f1c4";
}

.bi-box-arrow-up-right::before {
  content: "\f1c5";
}

.bi-box-arrow-up::before {
  content: "\f1c6";
}

.bi-box-seam::before {
  content: "\f1c7";
}

.bi-box::before {
  content: "\f1c8";
}

.bi-braces::before {
  content: "\f1c9";
}

.bi-bricks::before {
  content: "\f1ca";
}

.bi-briefcase-fill::before {
  content: "\f1cb";
}

.bi-briefcase::before {
  content: "\f1cc";
}

.bi-brightness-alt-high-fill::before {
  content: "\f1cd";
}

.bi-brightness-alt-high::before {
  content: "\f1ce";
}

.bi-brightness-alt-low-fill::before {
  content: "\f1cf";
}

.bi-brightness-alt-low::before {
  content: "\f1d0";
}

.bi-brightness-high-fill::before {
  content: "\f1d1";
}

.bi-brightness-high::before {
  content: "\f1d2";
}

.bi-brightness-low-fill::before {
  content: "\f1d3";
}

.bi-brightness-low::before {
  content: "\f1d4";
}

.bi-broadcast-pin::before {
  content: "\f1d5";
}

.bi-broadcast::before {
  content: "\f1d6";
}

.bi-brush-fill::before {
  content: "\f1d7";
}

.bi-brush::before {
  content: "\f1d8";
}

.bi-bucket-fill::before {
  content: "\f1d9";
}

.bi-bucket::before {
  content: "\f1da";
}

.bi-bug-fill::before {
  content: "\f1db";
}

.bi-bug::before {
  content: "\f1dc";
}

.bi-building::before {
  content: "\f1dd";
}

.bi-bullseye::before {
  content: "\f1de";
}

.bi-calculator-fill::before {
  content: "\f1df";
}

.bi-calculator::before {
  content: "\f1e0";
}

.bi-calendar-check-fill::before {
  content: "\f1e1";
}

.bi-calendar-check::before {
  content: "\f1e2";
}

.bi-calendar-date-fill::before {
  content: "\f1e3";
}

.bi-calendar-date::before {
  content: "\f1e4";
}

.bi-calendar-day-fill::before {
  content: "\f1e5";
}

.bi-calendar-day::before {
  content: "\f1e6";
}

.bi-calendar-event-fill::before {
  content: "\f1e7";
}

.bi-calendar-event::before {
  content: "\f1e8";
}

.bi-calendar-fill::before {
  content: "\f1e9";
}

.bi-calendar-minus-fill::before {
  content: "\f1ea";
}

.bi-calendar-minus::before {
  content: "\f1eb";
}

.bi-calendar-month-fill::before {
  content: "\f1ec";
}

.bi-calendar-month::before {
  content: "\f1ed";
}

.bi-calendar-plus-fill::before {
  content: "\f1ee";
}

.bi-calendar-plus::before {
  content: "\f1ef";
}

.bi-calendar-range-fill::before {
  content: "\f1f0";
}

.bi-calendar-range::before {
  content: "\f1f1";
}

.bi-calendar-week-fill::before {
  content: "\f1f2";
}

.bi-calendar-week::before {
  content: "\f1f3";
}

.bi-calendar-x-fill::before {
  content: "\f1f4";
}

.bi-calendar-x::before {
  content: "\f1f5";
}

.bi-calendar::before {
  content: "\f1f6";
}

.bi-calendar2-check-fill::before {
  content: "\f1f7";
}

.bi-calendar2-check::before {
  content: "\f1f8";
}

.bi-calendar2-date-fill::before {
  content: "\f1f9";
}

.bi-calendar2-date::before {
  content: "\f1fa";
}

.bi-calendar2-day-fill::before {
  content: "\f1fb";
}

.bi-calendar2-day::before {
  content: "\f1fc";
}

.bi-calendar2-event-fill::before {
  content: "\f1fd";
}

.bi-calendar2-event::before {
  content: "\f1fe";
}

.bi-calendar2-fill::before {
  content: "\f1ff";
}

.bi-calendar2-minus-fill::before {
  content: "\f200";
}

.bi-calendar2-minus::before {
  content: "\f201";
}

.bi-calendar2-month-fill::before {
  content: "\f202";
}

.bi-calendar2-month::before {
  content: "\f203";
}

.bi-calendar2-plus-fill::before {
  content: "\f204";
}

.bi-calendar2-plus::before {
  content: "\f205";
}

.bi-calendar2-range-fill::before {
  content: "\f206";
}

.bi-calendar2-range::before {
  content: "\f207";
}

.bi-calendar2-week-fill::before {
  content: "\f208";
}

.bi-calendar2-week::before {
  content: "\f209";
}

.bi-calendar2-x-fill::before {
  content: "\f20a";
}

.bi-calendar2-x::before {
  content: "\f20b";
}

.bi-calendar2::before {
  content: "\f20c";
}

.bi-calendar3-event-fill::before {
  content: "\f20d";
}

.bi-calendar3-event::before {
  content: "\f20e";
}

.bi-calendar3-fill::before {
  content: "\f20f";
}

.bi-calendar3-range-fill::before {
  content: "\f210";
}

.bi-calendar3-range::before {
  content: "\f211";
}

.bi-calendar3-week-fill::before {
  content: "\f212";
}

.bi-calendar3-week::before {
  content: "\f213";
}

.bi-calendar3::before {
  content: "\f214";
}

.bi-calendar4-event::before {
  content: "\f215";
}

.bi-calendar4-range::before {
  content: "\f216";
}

.bi-calendar4-week::before {
  content: "\f217";
}

.bi-calendar4::before {
  content: "\f218";
}

.bi-camera-fill::before {
  content: "\f219";
}

.bi-camera-reels-fill::before {
  content: "\f21a";
}

.bi-camera-reels::before {
  content: "\f21b";
}

.bi-camera-video-fill::before {
  content: "\f21c";
}

.bi-camera-video-off-fill::before {
  content: "\f21d";
}

.bi-camera-video-off::before {
  content: "\f21e";
}

.bi-camera-video::before {
  content: "\f21f";
}

.bi-camera::before {
  content: "\f220";
}

.bi-camera2::before {
  content: "\f221";
}

.bi-capslock-fill::before {
  content: "\f222";
}

.bi-capslock::before {
  content: "\f223";
}

.bi-card-checklist::before {
  content: "\f224";
}

.bi-card-heading::before {
  content: "\f225";
}

.bi-card-image::before {
  content: "\f226";
}

.bi-card-list::before {
  content: "\f227";
}

.bi-card-text::before {
  content: "\f228";
}

.bi-caret-down-fill::before {
  content: "\f229";
}

.bi-caret-down-square-fill::before {
  content: "\f22a";
}

.bi-caret-down-square::before {
  content: "\f22b";
}

.bi-caret-down::before {
  content: "\f22c";
}

.bi-caret-left-fill::before {
  content: "\f22d";
}

.bi-caret-left-square-fill::before {
  content: "\f22e";
}

.bi-caret-left-square::before {
  content: "\f22f";
}

.bi-caret-left::before {
  content: "\f230";
}

.bi-caret-right-fill::before {
  content: "\f231";
}

.bi-caret-right-square-fill::before {
  content: "\f232";
}

.bi-caret-right-square::before {
  content: "\f233";
}

.bi-caret-right::before {
  content: "\f234";
}

.bi-caret-up-fill::before {
  content: "\f235";
}

.bi-caret-up-square-fill::before {
  content: "\f236";
}

.bi-caret-up-square::before {
  content: "\f237";
}

.bi-caret-up::before {
  content: "\f238";
}

.bi-cart-check-fill::before {
  content: "\f239";
}

.bi-cart-check::before {
  content: "\f23a";
}

.bi-cart-dash-fill::before {
  content: "\f23b";
}

.bi-cart-dash::before {
  content: "\f23c";
}

.bi-cart-fill::before {
  content: "\f23d";
}

.bi-cart-plus-fill::before {
  content: "\f23e";
}

.bi-cart-plus::before {
  content: "\f23f";
}

.bi-cart-x-fill::before {
  content: "\f240";
}

.bi-cart-x::before {
  content: "\f241";
}

.bi-cart::before {
  content: "\f242";
}

.bi-cart2::before {
  content: "\f243";
}

.bi-cart3::before {
  content: "\f244";
}

.bi-cart4::before {
  content: "\f245";
}

.bi-cash-stack::before {
  content: "\f246";
}

.bi-cash::before {
  content: "\f247";
}

.bi-cast::before {
  content: "\f248";
}

.bi-chat-dots-fill::before {
  content: "\f249";
}

.bi-chat-dots::before {
  content: "\f24a";
}

.bi-chat-fill::before {
  content: "\f24b";
}

.bi-chat-left-dots-fill::before {
  content: "\f24c";
}

.bi-chat-left-dots::before {
  content: "\f24d";
}

.bi-chat-left-fill::before {
  content: "\f24e";
}

.bi-chat-left-quote-fill::before {
  content: "\f24f";
}

.bi-chat-left-quote::before {
  content: "\f250";
}

.bi-chat-left-text-fill::before {
  content: "\f251";
}

.bi-chat-left-text::before {
  content: "\f252";
}

.bi-chat-left::before {
  content: "\f253";
}

.bi-chat-quote-fill::before {
  content: "\f254";
}

.bi-chat-quote::before {
  content: "\f255";
}

.bi-chat-right-dots-fill::before {
  content: "\f256";
}

.bi-chat-right-dots::before {
  content: "\f257";
}

.bi-chat-right-fill::before {
  content: "\f258";
}

.bi-chat-right-quote-fill::before {
  content: "\f259";
}

.bi-chat-right-quote::before {
  content: "\f25a";
}

.bi-chat-right-text-fill::before {
  content: "\f25b";
}

.bi-chat-right-text::before {
  content: "\f25c";
}

.bi-chat-right::before {
  content: "\f25d";
}

.bi-chat-square-dots-fill::before {
  content: "\f25e";
}

.bi-chat-square-dots::before {
  content: "\f25f";
}

.bi-chat-square-fill::before {
  content: "\f260";
}

.bi-chat-square-quote-fill::before {
  content: "\f261";
}

.bi-chat-square-quote::before {
  content: "\f262";
}

.bi-chat-square-text-fill::before {
  content: "\f263";
}

.bi-chat-square-text::before {
  content: "\f264";
}

.bi-chat-square::before {
  content: "\f265";
}

.bi-chat-text-fill::before {
  content: "\f266";
}

.bi-chat-text::before {
  content: "\f267";
}

.bi-chat::before {
  content: "\f268";
}

.bi-check-all::before {
  content: "\f269";
}

.bi-check-circle-fill::before {
  content: "\f26a";
}

.bi-check-circle::before {
  content: "\f26b";
}

.bi-check-square-fill::before {
  content: "\f26c";
}

.bi-check-square::before {
  content: "\f26d";
}

.bi-check::before {
  content: "\f26e";
}

.bi-check2-all::before {
  content: "\f26f";
}

.bi-check2-circle::before {
  content: "\f270";
}

.bi-check2-square::before {
  content: "\f271";
}

.bi-check2::before {
  content: "\f272";
}

.bi-chevron-bar-contract::before {
  content: "\f273";
}

.bi-chevron-bar-down::before {
  content: "\f274";
}

.bi-chevron-bar-expand::before {
  content: "\f275";
}

.bi-chevron-bar-left::before {
  content: "\f276";
}

.bi-chevron-bar-right::before {
  content: "\f277";
}

.bi-chevron-bar-up::before {
  content: "\f278";
}

.bi-chevron-compact-down::before {
  content: "\f279";
}

.bi-chevron-compact-left::before {
  content: "\f27a";
}

.bi-chevron-compact-right::before {
  content: "\f27b";
}

.bi-chevron-compact-up::before {
  content: "\f27c";
}

.bi-chevron-contract::before {
  content: "\f27d";
}

.bi-chevron-double-down::before {
  content: "\f27e";
}

.bi-chevron-double-left::before {
  content: "\f27f";
}

.bi-chevron-double-right::before {
  content: "\f280";
}

.bi-chevron-double-up::before {
  content: "\f281";
}

.bi-chevron-down::before {
  content: "\f282";
}

.bi-chevron-expand::before {
  content: "\f283";
}

.bi-chevron-left::before {
  content: "\f284";
}

.bi-chevron-right::before {
  content: "\f285";
}

.bi-chevron-up::before {
  content: "\f286";
}

.bi-circle-fill::before {
  content: "\f287";
}

.bi-circle-half::before {
  content: "\f288";
}

.bi-circle-square::before {
  content: "\f289";
}

.bi-circle::before {
  content: "\f28a";
}

.bi-clipboard-check::before {
  content: "\f28b";
}

.bi-clipboard-data::before {
  content: "\f28c";
}

.bi-clipboard-minus::before {
  content: "\f28d";
}

.bi-clipboard-plus::before {
  content: "\f28e";
}

.bi-clipboard-x::before {
  content: "\f28f";
}

.bi-clipboard::before {
  content: "\f290";
}

.bi-clock-fill::before {
  content: "\f291";
}

.bi-clock-history::before {
  content: "\f292";
}

.bi-clock::before {
  content: "\f293";
}

.bi-cloud-arrow-down-fill::before {
  content: "\f294";
}

.bi-cloud-arrow-down::before {
  content: "\f295";
}

.bi-cloud-arrow-up-fill::before {
  content: "\f296";
}

.bi-cloud-arrow-up::before {
  content: "\f297";
}

.bi-cloud-check-fill::before {
  content: "\f298";
}

.bi-cloud-check::before {
  content: "\f299";
}

.bi-cloud-download-fill::before {
  content: "\f29a";
}

.bi-cloud-download::before {
  content: "\f29b";
}

.bi-cloud-fill::before {
  content: "\f29c";
}

.bi-cloud-minus-fill::before {
  content: "\f29d";
}

.bi-cloud-minus::before {
  content: "\f29e";
}

.bi-cloud-plus-fill::before {
  content: "\f29f";
}

.bi-cloud-plus::before {
  content: "\f2a0";
}

.bi-cloud-slash-fill::before {
  content: "\f2a1";
}

.bi-cloud-slash::before {
  content: "\f2a2";
}

.bi-cloud-upload-fill::before {
  content: "\f2a3";
}

.bi-cloud-upload::before {
  content: "\f2a4";
}

.bi-cloud::before {
  content: "\f2a5";
}

.bi-code-slash::before {
  content: "\f2a6";
}

.bi-code-square::before {
  content: "\f2a7";
}

.bi-code::before {
  content: "\f2a8";
}

.bi-collection-fill::before {
  content: "\f2a9";
}

.bi-collection-play-fill::before {
  content: "\f2aa";
}

.bi-collection-play::before {
  content: "\f2ab";
}

.bi-collection::before {
  content: "\f2ac";
}

.bi-columns-gap::before {
  content: "\f2ad";
}

.bi-columns::before {
  content: "\f2ae";
}

.bi-command::before {
  content: "\f2af";
}

.bi-compass-fill::before {
  content: "\f2b0";
}

.bi-compass::before {
  content: "\f2b1";
}

.bi-cone-striped::before {
  content: "\f2b2";
}

.bi-cone::before {
  content: "\f2b3";
}

.bi-controller::before {
  content: "\f2b4";
}

.bi-cpu-fill::before {
  content: "\f2b5";
}

.bi-cpu::before {
  content: "\f2b6";
}

.bi-credit-card-2-back-fill::before {
  content: "\f2b7";
}

.bi-credit-card-2-back::before {
  content: "\f2b8";
}

.bi-credit-card-2-front-fill::before {
  content: "\f2b9";
}

.bi-credit-card-2-front::before {
  content: "\f2ba";
}

.bi-credit-card-fill::before {
  content: "\f2bb";
}

.bi-credit-card::before {
  content: "\f2bc";
}

.bi-crop::before {
  content: "\f2bd";
}

.bi-cup-fill::before {
  content: "\f2be";
}

.bi-cup-straw::before {
  content: "\f2bf";
}

.bi-cup::before {
  content: "\f2c0";
}

.bi-cursor-fill::before {
  content: "\f2c1";
}

.bi-cursor-text::before {
  content: "\f2c2";
}

.bi-cursor::before {
  content: "\f2c3";
}

.bi-dash-circle-dotted::before {
  content: "\f2c4";
}

.bi-dash-circle-fill::before {
  content: "\f2c5";
}

.bi-dash-circle::before {
  content: "\f2c6";
}

.bi-dash-square-dotted::before {
  content: "\f2c7";
}

.bi-dash-square-fill::before {
  content: "\f2c8";
}

.bi-dash-square::before {
  content: "\f2c9";
}

.bi-dash::before {
  content: "\f2ca";
}

.bi-diagram-2-fill::before {
  content: "\f2cb";
}

.bi-diagram-2::before {
  content: "\f2cc";
}

.bi-diagram-3-fill::before {
  content: "\f2cd";
}

.bi-diagram-3::before {
  content: "\f2ce";
}

.bi-diamond-fill::before {
  content: "\f2cf";
}

.bi-diamond-half::before {
  content: "\f2d0";
}

.bi-diamond::before {
  content: "\f2d1";
}

.bi-dice-1-fill::before {
  content: "\f2d2";
}

.bi-dice-1::before {
  content: "\f2d3";
}

.bi-dice-2-fill::before {
  content: "\f2d4";
}

.bi-dice-2::before {
  content: "\f2d5";
}

.bi-dice-3-fill::before {
  content: "\f2d6";
}

.bi-dice-3::before {
  content: "\f2d7";
}

.bi-dice-4-fill::before {
  content: "\f2d8";
}

.bi-dice-4::before {
  content: "\f2d9";
}

.bi-dice-5-fill::before {
  content: "\f2da";
}

.bi-dice-5::before {
  content: "\f2db";
}

.bi-dice-6-fill::before {
  content: "\f2dc";
}

.bi-dice-6::before {
  content: "\f2dd";
}

.bi-disc-fill::before {
  content: "\f2de";
}

.bi-disc::before {
  content: "\f2df";
}

.bi-discord::before {
  content: "\f2e0";
}

.bi-display-fill::before {
  content: "\f2e1";
}

.bi-display::before {
  content: "\f2e2";
}

.bi-distribute-horizontal::before {
  content: "\f2e3";
}

.bi-distribute-vertical::before {
  content: "\f2e4";
}

.bi-door-closed-fill::before {
  content: "\f2e5";
}

.bi-door-closed::before {
  content: "\f2e6";
}

.bi-door-open-fill::before {
  content: "\f2e7";
}

.bi-door-open::before {
  content: "\f2e8";
}

.bi-dot::before {
  content: "\f2e9";
}

.bi-download::before {
  content: "\f2ea";
}

.bi-droplet-fill::before {
  content: "\f2eb";
}

.bi-droplet-half::before {
  content: "\f2ec";
}

.bi-droplet::before {
  content: "\f2ed";
}

.bi-earbuds::before {
  content: "\f2ee";
}

.bi-easel-fill::before {
  content: "\f2ef";
}

.bi-easel::before {
  content: "\f2f0";
}

.bi-egg-fill::before {
  content: "\f2f1";
}

.bi-egg-fried::before {
  content: "\f2f2";
}

.bi-egg::before {
  content: "\f2f3";
}

.bi-eject-fill::before {
  content: "\f2f4";
}

.bi-eject::before {
  content: "\f2f5";
}

.bi-emoji-angry-fill::before {
  content: "\f2f6";
}

.bi-emoji-angry::before {
  content: "\f2f7";
}

.bi-emoji-dizzy-fill::before {
  content: "\f2f8";
}

.bi-emoji-dizzy::before {
  content: "\f2f9";
}

.bi-emoji-expressionless-fill::before {
  content: "\f2fa";
}

.bi-emoji-expressionless::before {
  content: "\f2fb";
}

.bi-emoji-frown-fill::before {
  content: "\f2fc";
}

.bi-emoji-frown::before {
  content: "\f2fd";
}

.bi-emoji-heart-eyes-fill::before {
  content: "\f2fe";
}

.bi-emoji-heart-eyes::before {
  content: "\f2ff";
}

.bi-emoji-laughing-fill::before {
  content: "\f300";
}

.bi-emoji-laughing::before {
  content: "\f301";
}

.bi-emoji-neutral-fill::before {
  content: "\f302";
}

.bi-emoji-neutral::before {
  content: "\f303";
}

.bi-emoji-smile-fill::before {
  content: "\f304";
}

.bi-emoji-smile-upside-down-fill::before {
  content: "\f305";
}

.bi-emoji-smile-upside-down::before {
  content: "\f306";
}

.bi-emoji-smile::before {
  content: "\f307";
}

.bi-emoji-sunglasses-fill::before {
  content: "\f308";
}

.bi-emoji-sunglasses::before {
  content: "\f309";
}

.bi-emoji-wink-fill::before {
  content: "\f30a";
}

.bi-emoji-wink::before {
  content: "\f30b";
}

.bi-envelope-fill::before {
  content: "\f30c";
}

.bi-envelope-open-fill::before {
  content: "\f30d";
}

.bi-envelope-open::before {
  content: "\f30e";
}

.bi-envelope::before {
  content: "\f30f";
}

.bi-eraser-fill::before {
  content: "\f310";
}

.bi-eraser::before {
  content: "\f311";
}

.bi-exclamation-circle-fill::before {
  content: "\f312";
}

.bi-exclamation-circle::before {
  content: "\f313";
}

.bi-exclamation-diamond-fill::before {
  content: "\f314";
}

.bi-exclamation-diamond::before {
  content: "\f315";
}

.bi-exclamation-octagon-fill::before {
  content: "\f316";
}

.bi-exclamation-octagon::before {
  content: "\f317";
}

.bi-exclamation-square-fill::before {
  content: "\f318";
}

.bi-exclamation-square::before {
  content: "\f319";
}

.bi-exclamation-triangle-fill::before {
  content: "\f31a";
}

.bi-exclamation-triangle::before {
  content: "\f31b";
}

.bi-exclamation::before {
  content: "\f31c";
}

.bi-exclude::before {
  content: "\f31d";
}

.bi-eye-fill::before {
  content: "\f31e";
}

.bi-eye-slash-fill::before {
  content: "\f31f";
}

.bi-eye-slash::before {
  content: "\f320";
}

.bi-eye::before {
  content: "\f321";
}

.bi-eyedropper::before {
  content: "\f322";
}

.bi-eyeglasses::before {
  content: "\f323";
}

.bi-facebook::before {
  content: "\f324";
}

.bi-file-arrow-down-fill::before {
  content: "\f325";
}

.bi-file-arrow-down::before {
  content: "\f326";
}

.bi-file-arrow-up-fill::before {
  content: "\f327";
}

.bi-file-arrow-up::before {
  content: "\f328";
}

.bi-file-bar-graph-fill::before {
  content: "\f329";
}

.bi-file-bar-graph::before {
  content: "\f32a";
}

.bi-file-binary-fill::before {
  content: "\f32b";
}

.bi-file-binary::before {
  content: "\f32c";
}

.bi-file-break-fill::before {
  content: "\f32d";
}

.bi-file-break::before {
  content: "\f32e";
}

.bi-file-check-fill::before {
  content: "\f32f";
}

.bi-file-check::before {
  content: "\f330";
}

.bi-file-code-fill::before {
  content: "\f331";
}

.bi-file-code::before {
  content: "\f332";
}

.bi-file-diff-fill::before {
  content: "\f333";
}

.bi-file-diff::before {
  content: "\f334";
}

.bi-file-earmark-arrow-down-fill::before {
  content: "\f335";
}

.bi-file-earmark-arrow-down::before {
  content: "\f336";
}

.bi-file-earmark-arrow-up-fill::before {
  content: "\f337";
}

.bi-file-earmark-arrow-up::before {
  content: "\f338";
}

.bi-file-earmark-bar-graph-fill::before {
  content: "\f339";
}

.bi-file-earmark-bar-graph::before {
  content: "\f33a";
}

.bi-file-earmark-binary-fill::before {
  content: "\f33b";
}

.bi-file-earmark-binary::before {
  content: "\f33c";
}

.bi-file-earmark-break-fill::before {
  content: "\f33d";
}

.bi-file-earmark-break::before {
  content: "\f33e";
}

.bi-file-earmark-check-fill::before {
  content: "\f33f";
}

.bi-file-earmark-check::before {
  content: "\f340";
}

.bi-file-earmark-code-fill::before {
  content: "\f341";
}

.bi-file-earmark-code::before {
  content: "\f342";
}

.bi-file-earmark-diff-fill::before {
  content: "\f343";
}

.bi-file-earmark-diff::before {
  content: "\f344";
}

.bi-file-earmark-easel-fill::before {
  content: "\f345";
}

.bi-file-earmark-easel::before {
  content: "\f346";
}

.bi-file-earmark-excel-fill::before {
  content: "\f347";
}

.bi-file-earmark-excel::before {
  content: "\f348";
}

.bi-file-earmark-fill::before {
  content: "\f349";
}

.bi-file-earmark-font-fill::before {
  content: "\f34a";
}

.bi-file-earmark-font::before {
  content: "\f34b";
}

.bi-file-earmark-image-fill::before {
  content: "\f34c";
}

.bi-file-earmark-image::before {
  content: "\f34d";
}

.bi-file-earmark-lock-fill::before {
  content: "\f34e";
}

.bi-file-earmark-lock::before {
  content: "\f34f";
}

.bi-file-earmark-lock2-fill::before {
  content: "\f350";
}

.bi-file-earmark-lock2::before {
  content: "\f351";
}

.bi-file-earmark-medical-fill::before {
  content: "\f352";
}

.bi-file-earmark-medical::before {
  content: "\f353";
}

.bi-file-earmark-minus-fill::before {
  content: "\f354";
}

.bi-file-earmark-minus::before {
  content: "\f355";
}

.bi-file-earmark-music-fill::before {
  content: "\f356";
}

.bi-file-earmark-music::before {
  content: "\f357";
}

.bi-file-earmark-person-fill::before {
  content: "\f358";
}

.bi-file-earmark-person::before {
  content: "\f359";
}

.bi-file-earmark-play-fill::before {
  content: "\f35a";
}

.bi-file-earmark-play::before {
  content: "\f35b";
}

.bi-file-earmark-plus-fill::before {
  content: "\f35c";
}

.bi-file-earmark-plus::before {
  content: "\f35d";
}

.bi-file-earmark-post-fill::before {
  content: "\f35e";
}

.bi-file-earmark-post::before {
  content: "\f35f";
}

.bi-file-earmark-ppt-fill::before {
  content: "\f360";
}

.bi-file-earmark-ppt::before {
  content: "\f361";
}

.bi-file-earmark-richtext-fill::before {
  content: "\f362";
}

.bi-file-earmark-richtext::before {
  content: "\f363";
}

.bi-file-earmark-ruled-fill::before {
  content: "\f364";
}

.bi-file-earmark-ruled::before {
  content: "\f365";
}

.bi-file-earmark-slides-fill::before {
  content: "\f366";
}

.bi-file-earmark-slides::before {
  content: "\f367";
}

.bi-file-earmark-spreadsheet-fill::before {
  content: "\f368";
}

.bi-file-earmark-spreadsheet::before {
  content: "\f369";
}

.bi-file-earmark-text-fill::before {
  content: "\f36a";
}

.bi-file-earmark-text::before {
  content: "\f36b";
}

.bi-file-earmark-word-fill::before {
  content: "\f36c";
}

.bi-file-earmark-word::before {
  content: "\f36d";
}

.bi-file-earmark-x-fill::before {
  content: "\f36e";
}

.bi-file-earmark-x::before {
  content: "\f36f";
}

.bi-file-earmark-zip-fill::before {
  content: "\f370";
}

.bi-file-earmark-zip::before {
  content: "\f371";
}

.bi-file-earmark::before {
  content: "\f372";
}

.bi-file-easel-fill::before {
  content: "\f373";
}

.bi-file-easel::before {
  content: "\f374";
}

.bi-file-excel-fill::before {
  content: "\f375";
}

.bi-file-excel::before {
  content: "\f376";
}

.bi-file-fill::before {
  content: "\f377";
}

.bi-file-font-fill::before {
  content: "\f378";
}

.bi-file-font::before {
  content: "\f379";
}

.bi-file-image-fill::before {
  content: "\f37a";
}

.bi-file-image::before {
  content: "\f37b";
}

.bi-file-lock-fill::before {
  content: "\f37c";
}

.bi-file-lock::before {
  content: "\f37d";
}

.bi-file-lock2-fill::before {
  content: "\f37e";
}

.bi-file-lock2::before {
  content: "\f37f";
}

.bi-file-medical-fill::before {
  content: "\f380";
}

.bi-file-medical::before {
  content: "\f381";
}

.bi-file-minus-fill::before {
  content: "\f382";
}

.bi-file-minus::before {
  content: "\f383";
}

.bi-file-music-fill::before {
  content: "\f384";
}

.bi-file-music::before {
  content: "\f385";
}

.bi-file-person-fill::before {
  content: "\f386";
}

.bi-file-person::before {
  content: "\f387";
}

.bi-file-play-fill::before {
  content: "\f388";
}

.bi-file-play::before {
  content: "\f389";
}

.bi-file-plus-fill::before {
  content: "\f38a";
}

.bi-file-plus::before {
  content: "\f38b";
}

.bi-file-post-fill::before {
  content: "\f38c";
}

.bi-file-post::before {
  content: "\f38d";
}

.bi-file-ppt-fill::before {
  content: "\f38e";
}

.bi-file-ppt::before {
  content: "\f38f";
}

.bi-file-richtext-fill::before {
  content: "\f390";
}

.bi-file-richtext::before {
  content: "\f391";
}

.bi-file-ruled-fill::before {
  content: "\f392";
}

.bi-file-ruled::before {
  content: "\f393";
}

.bi-file-slides-fill::before {
  content: "\f394";
}

.bi-file-slides::before {
  content: "\f395";
}

.bi-file-spreadsheet-fill::before {
  content: "\f396";
}

.bi-file-spreadsheet::before {
  content: "\f397";
}

.bi-file-text-fill::before {
  content: "\f398";
}

.bi-file-text::before {
  content: "\f399";
}

.bi-file-word-fill::before {
  content: "\f39a";
}

.bi-file-word::before {
  content: "\f39b";
}

.bi-file-x-fill::before {
  content: "\f39c";
}

.bi-file-x::before {
  content: "\f39d";
}

.bi-file-zip-fill::before {
  content: "\f39e";
}

.bi-file-zip::before {
  content: "\f39f";
}

.bi-file::before {
  content: "\f3a0";
}

.bi-files-alt::before {
  content: "\f3a1";
}

.bi-files::before {
  content: "\f3a2";
}

.bi-film::before {
  content: "\f3a3";
}

.bi-filter-circle-fill::before {
  content: "\f3a4";
}

.bi-filter-circle::before {
  content: "\f3a5";
}

.bi-filter-left::before {
  content: "\f3a6";
}

.bi-filter-right::before {
  content: "\f3a7";
}

.bi-filter-square-fill::before {
  content: "\f3a8";
}

.bi-filter-square::before {
  content: "\f3a9";
}

.bi-filter::before {
  content: "\f3aa";
}

.bi-flag-fill::before {
  content: "\f3ab";
}

.bi-flag::before {
  content: "\f3ac";
}

.bi-flower1::before {
  content: "\f3ad";
}

.bi-flower2::before {
  content: "\f3ae";
}

.bi-flower3::before {
  content: "\f3af";
}

.bi-folder-check::before {
  content: "\f3b0";
}

.bi-folder-fill::before {
  content: "\f3b1";
}

.bi-folder-minus::before {
  content: "\f3b2";
}

.bi-folder-plus::before {
  content: "\f3b3";
}

.bi-folder-symlink-fill::before {
  content: "\f3b4";
}

.bi-folder-symlink::before {
  content: "\f3b5";
}

.bi-folder-x::before {
  content: "\f3b6";
}

.bi-folder::before {
  content: "\f3b7";
}

.bi-folder2-open::before {
  content: "\f3b8";
}

.bi-folder2::before {
  content: "\f3b9";
}

.bi-fonts::before {
  content: "\f3ba";
}

.bi-forward-fill::before {
  content: "\f3bb";
}

.bi-forward::before {
  content: "\f3bc";
}

.bi-front::before {
  content: "\f3bd";
}

.bi-fullscreen-exit::before {
  content: "\f3be";
}

.bi-fullscreen::before {
  content: "\f3bf";
}

.bi-funnel-fill::before {
  content: "\f3c0";
}

.bi-funnel::before {
  content: "\f3c1";
}

.bi-gear-fill::before {
  content: "\f3c2";
}

.bi-gear-wide-connected::before {
  content: "\f3c3";
}

.bi-gear-wide::before {
  content: "\f3c4";
}

.bi-gear::before {
  content: "\f3c5";
}

.bi-gem::before {
  content: "\f3c6";
}

.bi-geo-alt-fill::before {
  content: "\f3c7";
}

.bi-geo-alt::before {
  content: "\f3c8";
}

.bi-geo-fill::before {
  content: "\f3c9";
}

.bi-geo::before {
  content: "\f3ca";
}

.bi-gift-fill::before {
  content: "\f3cb";
}

.bi-gift::before {
  content: "\f3cc";
}

.bi-github::before {
  content: "\f3cd";
}

.bi-globe::before {
  content: "\f3ce";
}

.bi-globe2::before {
  content: "\f3cf";
}

.bi-google::before {
  content: "\f3d0";
}

.bi-graph-down::before {
  content: "\f3d1";
}

.bi-graph-up::before {
  content: "\f3d2";
}

.bi-grid-1x2-fill::before {
  content: "\f3d3";
}

.bi-grid-1x2::before {
  content: "\f3d4";
}

.bi-grid-3x2-gap-fill::before {
  content: "\f3d5";
}

.bi-grid-3x2-gap::before {
  content: "\f3d6";
}

.bi-grid-3x2::before {
  content: "\f3d7";
}

.bi-grid-3x3-gap-fill::before {
  content: "\f3d8";
}

.bi-grid-3x3-gap::before {
  content: "\f3d9";
}

.bi-grid-3x3::before {
  content: "\f3da";
}

.bi-grid-fill::before {
  content: "\f3db";
}

.bi-grid::before {
  content: "\f3dc";
}

.bi-grip-horizontal::before {
  content: "\f3dd";
}

.bi-grip-vertical::before {
  content: "\f3de";
}

.bi-hammer::before {
  content: "\f3df";
}

.bi-hand-index-fill::before {
  content: "\f3e0";
}

.bi-hand-index-thumb-fill::before {
  content: "\f3e1";
}

.bi-hand-index-thumb::before {
  content: "\f3e2";
}

.bi-hand-index::before {
  content: "\f3e3";
}

.bi-hand-thumbs-down-fill::before {
  content: "\f3e4";
}

.bi-hand-thumbs-down::before {
  content: "\f3e5";
}

.bi-hand-thumbs-up-fill::before {
  content: "\f3e6";
}

.bi-hand-thumbs-up::before {
  content: "\f3e7";
}

.bi-handbag-fill::before {
  content: "\f3e8";
}

.bi-handbag::before {
  content: "\f3e9";
}

.bi-hash::before {
  content: "\f3ea";
}

.bi-hdd-fill::before {
  content: "\f3eb";
}

.bi-hdd-network-fill::before {
  content: "\f3ec";
}

.bi-hdd-network::before {
  content: "\f3ed";
}

.bi-hdd-rack-fill::before {
  content: "\f3ee";
}

.bi-hdd-rack::before {
  content: "\f3ef";
}

.bi-hdd-stack-fill::before {
  content: "\f3f0";
}

.bi-hdd-stack::before {
  content: "\f3f1";
}

.bi-hdd::before {
  content: "\f3f2";
}

.bi-headphones::before {
  content: "\f3f3";
}

.bi-headset::before {
  content: "\f3f4";
}

.bi-heart-fill::before {
  content: "\f3f5";
}

.bi-heart-half::before {
  content: "\f3f6";
}

.bi-heart::before {
  content: "\f3f7";
}

.bi-heptagon-fill::before {
  content: "\f3f8";
}

.bi-heptagon-half::before {
  content: "\f3f9";
}

.bi-heptagon::before {
  content: "\f3fa";
}

.bi-hexagon-fill::before {
  content: "\f3fb";
}

.bi-hexagon-half::before {
  content: "\f3fc";
}

.bi-hexagon::before {
  content: "\f3fd";
}

.bi-hourglass-bottom::before {
  content: "\f3fe";
}

.bi-hourglass-split::before {
  content: "\f3ff";
}

.bi-hourglass-top::before {
  content: "\f400";
}

.bi-hourglass::before {
  content: "\f401";
}

.bi-house-door-fill::before {
  content: "\f402";
}

.bi-house-door::before {
  content: "\f403";
}

.bi-house-fill::before {
  content: "\f404";
}

.bi-house::before {
  content: "\f405";
}

.bi-hr::before {
  content: "\f406";
}

.bi-image-alt::before {
  content: "\f407";
}

.bi-image-fill::before {
  content: "\f408";
}

.bi-image::before {
  content: "\f409";
}

.bi-images::before {
  content: "\f40a";
}

.bi-inbox-fill::before {
  content: "\f40b";
}

.bi-inbox::before {
  content: "\f40c";
}

.bi-inboxes-fill::before {
  content: "\f40d";
}

.bi-inboxes::before {
  content: "\f40e";
}

.bi-info-circle-fill::before {
  content: "\f40f";
}

.bi-info-circle::before {
  content: "\f410";
}

.bi-info-square-fill::before {
  content: "\f411";
}

.bi-info-square::before {
  content: "\f412";
}

.bi-info::before {
  content: "\f413";
}

.bi-input-cursor-text::before {
  content: "\f414";
}

.bi-input-cursor::before {
  content: "\f415";
}

.bi-instagram::before {
  content: "\f416";
}

.bi-intersect::before {
  content: "\f417";
}

.bi-journal-album::before {
  content: "\f418";
}

.bi-journal-arrow-down::before {
  content: "\f419";
}

.bi-journal-arrow-up::before {
  content: "\f41a";
}

.bi-journal-bookmark-fill::before {
  content: "\f41b";
}

.bi-journal-bookmark::before {
  content: "\f41c";
}

.bi-journal-check::before {
  content: "\f41d";
}

.bi-journal-code::before {
  content: "\f41e";
}

.bi-journal-medical::before {
  content: "\f41f";
}

.bi-journal-minus::before {
  content: "\f420";
}

.bi-journal-plus::before {
  content: "\f421";
}

.bi-journal-richtext::before {
  content: "\f422";
}

.bi-journal-text::before {
  content: "\f423";
}

.bi-journal-x::before {
  content: "\f424";
}

.bi-journal::before {
  content: "\f425";
}

.bi-journals::before {
  content: "\f426";
}

.bi-joystick::before {
  content: "\f427";
}

.bi-justify-left::before {
  content: "\f428";
}

.bi-justify-right::before {
  content: "\f429";
}

.bi-justify::before {
  content: "\f42a";
}

.bi-kanban-fill::before {
  content: "\f42b";
}

.bi-kanban::before {
  content: "\f42c";
}

.bi-key-fill::before {
  content: "\f42d";
}

.bi-key::before {
  content: "\f42e";
}

.bi-keyboard-fill::before {
  content: "\f42f";
}

.bi-keyboard::before {
  content: "\f430";
}

.bi-ladder::before {
  content: "\f431";
}

.bi-lamp-fill::before {
  content: "\f432";
}

.bi-lamp::before {
  content: "\f433";
}

.bi-laptop-fill::before {
  content: "\f434";
}

.bi-laptop::before {
  content: "\f435";
}

.bi-layer-backward::before {
  content: "\f436";
}

.bi-layer-forward::before {
  content: "\f437";
}

.bi-layers-fill::before {
  content: "\f438";
}

.bi-layers-half::before {
  content: "\f439";
}

.bi-layers::before {
  content: "\f43a";
}

.bi-layout-sidebar-inset-reverse::before {
  content: "\f43b";
}

.bi-layout-sidebar-inset::before {
  content: "\f43c";
}

.bi-layout-sidebar-reverse::before {
  content: "\f43d";
}

.bi-layout-sidebar::before {
  content: "\f43e";
}

.bi-layout-split::before {
  content: "\f43f";
}

.bi-layout-text-sidebar-reverse::before {
  content: "\f440";
}

.bi-layout-text-sidebar::before {
  content: "\f441";
}

.bi-layout-text-window-reverse::before {
  content: "\f442";
}

.bi-layout-text-window::before {
  content: "\f443";
}

.bi-layout-three-columns::before {
  content: "\f444";
}

.bi-layout-wtf::before {
  content: "\f445";
}

.bi-life-preserver::before {
  content: "\f446";
}

.bi-lightbulb-fill::before {
  content: "\f447";
}

.bi-lightbulb-off-fill::before {
  content: "\f448";
}

.bi-lightbulb-off::before {
  content: "\f449";
}

.bi-lightbulb::before {
  content: "\f44a";
}

.bi-lightning-fill::before {
  content: "\f44b";
}

.bi-lightning::before {
  content: "\f44c";
}

.bi-link-45deg::before {
  content: "\f44d";
}

.bi-link::before {
  content: "\f44e";
}

.bi-linkedin::before {
  content: "\f44f";
}

.bi-list-check::before {
  content: "\f450";
}

.bi-list-nested::before {
  content: "\f451";
}

.bi-list-ol::before {
  content: "\f452";
}

.bi-list-stars::before {
  content: "\f453";
}

.bi-list-task::before {
  content: "\f454";
}

.bi-list-ul::before {
  content: "\f455";
}

.bi-list::before {
  content: "\f456";
}

.bi-lock-fill::before {
  content: "\f457";
}

.bi-lock::before {
  content: "\f458";
}

.bi-mailbox::before {
  content: "\f459";
}

.bi-mailbox2::before {
  content: "\f45a";
}

.bi-map-fill::before {
  content: "\f45b";
}

.bi-map::before {
  content: "\f45c";
}

.bi-markdown-fill::before {
  content: "\f45d";
}

.bi-markdown::before {
  content: "\f45e";
}

.bi-mask::before {
  content: "\f45f";
}

.bi-megaphone-fill::before {
  content: "\f460";
}

.bi-megaphone::before {
  content: "\f461";
}

.bi-menu-app-fill::before {
  content: "\f462";
}

.bi-menu-app::before {
  content: "\f463";
}

.bi-menu-button-fill::before {
  content: "\f464";
}

.bi-menu-button-wide-fill::before {
  content: "\f465";
}

.bi-menu-button-wide::before {
  content: "\f466";
}

.bi-menu-button::before {
  content: "\f467";
}

.bi-menu-down::before {
  content: "\f468";
}

.bi-menu-up::before {
  content: "\f469";
}

.bi-mic-fill::before {
  content: "\f46a";
}

.bi-mic-mute-fill::before {
  content: "\f46b";
}

.bi-mic-mute::before {
  content: "\f46c";
}

.bi-mic::before {
  content: "\f46d";
}

.bi-minecart-loaded::before {
  content: "\f46e";
}

.bi-minecart::before {
  content: "\f46f";
}

.bi-moon::before {
  content: "\f470";
}

.bi-mouse-fill::before {
  content: "\f471";
}

.bi-mouse::before {
  content: "\f472";
}

.bi-mouse2-fill::before {
  content: "\f473";
}

.bi-mouse2::before {
  content: "\f474";
}

.bi-mouse3-fill::before {
  content: "\f475";
}

.bi-mouse3::before {
  content: "\f476";
}

.bi-music-note-beamed::before {
  content: "\f477";
}

.bi-music-note-list::before {
  content: "\f478";
}

.bi-music-note::before {
  content: "\f479";
}

.bi-music-player-fill::before {
  content: "\f47a";
}

.bi-music-player::before {
  content: "\f47b";
}

.bi-newspaper::before {
  content: "\f47c";
}

.bi-node-minus-fill::before {
  content: "\f47d";
}

.bi-node-minus::before {
  content: "\f47e";
}

.bi-node-plus-fill::before {
  content: "\f47f";
}

.bi-node-plus::before {
  content: "\f480";
}

.bi-nut-fill::before {
  content: "\f481";
}

.bi-nut::before {
  content: "\f482";
}

.bi-octagon-fill::before {
  content: "\f483";
}

.bi-octagon-half::before {
  content: "\f484";
}

.bi-octagon::before {
  content: "\f485";
}

.bi-option::before {
  content: "\f486";
}

.bi-outlet::before {
  content: "\f487";
}

.bi-paint-bucket::before {
  content: "\f488";
}

.bi-palette-fill::before {
  content: "\f489";
}

.bi-palette::before {
  content: "\f48a";
}

.bi-palette2::before {
  content: "\f48b";
}

.bi-paperclip::before {
  content: "\f48c";
}

.bi-paragraph::before {
  content: "\f48d";
}

.bi-patch-check-fill::before {
  content: "\f48e";
}

.bi-patch-check::before {
  content: "\f48f";
}

.bi-patch-exclamation-fill::before {
  content: "\f490";
}

.bi-patch-exclamation::before {
  content: "\f491";
}

.bi-patch-minus-fill::before {
  content: "\f492";
}

.bi-patch-minus::before {
  content: "\f493";
}

.bi-patch-plus-fill::before {
  content: "\f494";
}

.bi-patch-plus::before {
  content: "\f495";
}

.bi-patch-question-fill::before {
  content: "\f496";
}

.bi-patch-question::before {
  content: "\f497";
}

.bi-pause-btn-fill::before {
  content: "\f498";
}

.bi-pause-btn::before {
  content: "\f499";
}

.bi-pause-circle-fill::before {
  content: "\f49a";
}

.bi-pause-circle::before {
  content: "\f49b";
}

.bi-pause-fill::before {
  content: "\f49c";
}

.bi-pause::before {
  content: "\f49d";
}

.bi-peace-fill::before {
  content: "\f49e";
}

.bi-peace::before {
  content: "\f49f";
}

.bi-pen-fill::before {
  content: "\f4a0";
}

.bi-pen::before {
  content: "\f4a1";
}

.bi-pencil-fill::before {
  content: "\f4a2";
}

.bi-pencil-square::before {
  content: "\f4a3";
}

.bi-pencil::before {
  content: "\f4a4";
}

.bi-pentagon-fill::before {
  content: "\f4a5";
}

.bi-pentagon-half::before {
  content: "\f4a6";
}

.bi-pentagon::before {
  content: "\f4a7";
}

.bi-people-fill::before {
  content: "\f4a8";
}

.bi-people::before {
  content: "\f4a9";
}

.bi-percent::before {
  content: "\f4aa";
}

.bi-person-badge-fill::before {
  content: "\f4ab";
}

.bi-person-badge::before {
  content: "\f4ac";
}

.bi-person-bounding-box::before {
  content: "\f4ad";
}

.bi-person-check-fill::before {
  content: "\f4ae";
}

.bi-person-check::before {
  content: "\f4af";
}

.bi-person-circle::before {
  content: "\f4b0";
}

.bi-person-dash-fill::before {
  content: "\f4b1";
}

.bi-person-dash::before {
  content: "\f4b2";
}

.bi-person-fill::before {
  content: "\f4b3";
}

.bi-person-lines-fill::before {
  content: "\f4b4";
}

.bi-person-plus-fill::before {
  content: "\f4b5";
}

.bi-person-plus::before {
  content: "\f4b6";
}

.bi-person-square::before {
  content: "\f4b7";
}

.bi-person-x-fill::before {
  content: "\f4b8";
}

.bi-person-x::before {
  content: "\f4b9";
}

.bi-person::before {
  content: "\f4ba";
}

.bi-phone-fill::before {
  content: "\f4bb";
}

.bi-phone-landscape-fill::before {
  content: "\f4bc";
}

.bi-phone-landscape::before {
  content: "\f4bd";
}

.bi-phone-vibrate-fill::before {
  content: "\f4be";
}

.bi-phone-vibrate::before {
  content: "\f4bf";
}

.bi-phone::before {
  content: "\f4c0";
}

.bi-pie-chart-fill::before {
  content: "\f4c1";
}

.bi-pie-chart::before {
  content: "\f4c2";
}

.bi-pin-angle-fill::before {
  content: "\f4c3";
}

.bi-pin-angle::before {
  content: "\f4c4";
}

.bi-pin-fill::before {
  content: "\f4c5";
}

.bi-pin::before {
  content: "\f4c6";
}

.bi-pip-fill::before {
  content: "\f4c7";
}

.bi-pip::before {
  content: "\f4c8";
}

.bi-play-btn-fill::before {
  content: "\f4c9";
}

.bi-play-btn::before {
  content: "\f4ca";
}

.bi-play-circle-fill::before {
  content: "\f4cb";
}

.bi-play-circle::before {
  content: "\f4cc";
}

.bi-play-fill::before {
  content: "\f4cd";
}

.bi-play::before {
  content: "\f4ce";
}

.bi-plug-fill::before {
  content: "\f4cf";
}

.bi-plug::before {
  content: "\f4d0";
}

.bi-plus-circle-dotted::before {
  content: "\f4d1";
}

.bi-plus-circle-fill::before {
  content: "\f4d2";
}

.bi-plus-circle::before {
  content: "\f4d3";
}

.bi-plus-square-dotted::before {
  content: "\f4d4";
}

.bi-plus-square-fill::before {
  content: "\f4d5";
}

.bi-plus-square::before {
  content: "\f4d6";
}

.bi-plus::before {
  content: "\f4d7";
}

.bi-power::before {
  content: "\f4d8";
}

.bi-printer-fill::before {
  content: "\f4d9";
}

.bi-printer::before {
  content: "\f4da";
}

.bi-puzzle-fill::before {
  content: "\f4db";
}

.bi-puzzle::before {
  content: "\f4dc";
}

.bi-question-circle-fill::before {
  content: "\f4dd";
}

.bi-question-circle::before {
  content: "\f4de";
}

.bi-question-diamond-fill::before {
  content: "\f4df";
}

.bi-question-diamond::before {
  content: "\f4e0";
}

.bi-question-octagon-fill::before {
  content: "\f4e1";
}

.bi-question-octagon::before {
  content: "\f4e2";
}

.bi-question-square-fill::before {
  content: "\f4e3";
}

.bi-question-square::before {
  content: "\f4e4";
}

.bi-question::before {
  content: "\f4e5";
}

.bi-receipt-cutoff::before {
  content: "\f4e6";
}

.bi-receipt::before {
  content: "\f4e7";
}

.bi-reception-0::before {
  content: "\f4e8";
}

.bi-reception-1::before {
  content: "\f4e9";
}

.bi-reception-2::before {
  content: "\f4ea";
}

.bi-reception-3::before {
  content: "\f4eb";
}

.bi-reception-4::before {
  content: "\f4ec";
}

.bi-record-btn-fill::before {
  content: "\f4ed";
}

.bi-record-btn::before {
  content: "\f4ee";
}

.bi-record-circle-fill::before {
  content: "\f4ef";
}

.bi-record-circle::before {
  content: "\f4f0";
}

.bi-record-fill::before {
  content: "\f4f1";
}

.bi-record::before {
  content: "\f4f2";
}

.bi-record2-fill::before {
  content: "\f4f3";
}

.bi-record2::before {
  content: "\f4f4";
}

.bi-reply-all-fill::before {
  content: "\f4f5";
}

.bi-reply-all::before {
  content: "\f4f6";
}

.bi-reply-fill::before {
  content: "\f4f7";
}

.bi-reply::before {
  content: "\f4f8";
}

.bi-rss-fill::before {
  content: "\f4f9";
}

.bi-rss::before {
  content: "\f4fa";
}

.bi-rulers::before {
  content: "\f4fb";
}

.bi-save-fill::before {
  content: "\f4fc";
}

.bi-save::before {
  content: "\f4fd";
}

.bi-save2-fill::before {
  content: "\f4fe";
}

.bi-save2::before {
  content: "\f4ff";
}

.bi-scissors::before {
  content: "\f500";
}

.bi-screwdriver::before {
  content: "\f501";
}

.bi-search::before {
  content: "\f502";
}

.bi-segmented-nav::before {
  content: "\f503";
}

.bi-server::before {
  content: "\f504";
}

.bi-share-fill::before {
  content: "\f505";
}

.bi-share::before {
  content: "\f506";
}

.bi-shield-check::before {
  content: "\f507";
}

.bi-shield-exclamation::before {
  content: "\f508";
}

.bi-shield-fill-check::before {
  content: "\f509";
}

.bi-shield-fill-exclamation::before {
  content: "\f50a";
}

.bi-shield-fill-minus::before {
  content: "\f50b";
}

.bi-shield-fill-plus::before {
  content: "\f50c";
}

.bi-shield-fill-x::before {
  content: "\f50d";
}

.bi-shield-fill::before {
  content: "\f50e";
}

.bi-shield-lock-fill::before {
  content: "\f50f";
}

.bi-shield-lock::before {
  content: "\f510";
}

.bi-shield-minus::before {
  content: "\f511";
}

.bi-shield-plus::before {
  content: "\f512";
}

.bi-shield-shaded::before {
  content: "\f513";
}

.bi-shield-slash-fill::before {
  content: "\f514";
}

.bi-shield-slash::before {
  content: "\f515";
}

.bi-shield-x::before {
  content: "\f516";
}

.bi-shield::before {
  content: "\f517";
}

.bi-shift-fill::before {
  content: "\f518";
}

.bi-shift::before {
  content: "\f519";
}

.bi-shop-window::before {
  content: "\f51a";
}

.bi-shop::before {
  content: "\f51b";
}

.bi-shuffle::before {
  content: "\f51c";
}

.bi-signpost-2-fill::before {
  content: "\f51d";
}

.bi-signpost-2::before {
  content: "\f51e";
}

.bi-signpost-fill::before {
  content: "\f51f";
}

.bi-signpost-split-fill::before {
  content: "\f520";
}

.bi-signpost-split::before {
  content: "\f521";
}

.bi-signpost::before {
  content: "\f522";
}

.bi-sim-fill::before {
  content: "\f523";
}

.bi-sim::before {
  content: "\f524";
}

.bi-skip-backward-btn-fill::before {
  content: "\f525";
}

.bi-skip-backward-btn::before {
  content: "\f526";
}

.bi-skip-backward-circle-fill::before {
  content: "\f527";
}

.bi-skip-backward-circle::before {
  content: "\f528";
}

.bi-skip-backward-fill::before {
  content: "\f529";
}

.bi-skip-backward::before {
  content: "\f52a";
}

.bi-skip-end-btn-fill::before {
  content: "\f52b";
}

.bi-skip-end-btn::before {
  content: "\f52c";
}

.bi-skip-end-circle-fill::before {
  content: "\f52d";
}

.bi-skip-end-circle::before {
  content: "\f52e";
}

.bi-skip-end-fill::before {
  content: "\f52f";
}

.bi-skip-end::before {
  content: "\f530";
}

.bi-skip-forward-btn-fill::before {
  content: "\f531";
}

.bi-skip-forward-btn::before {
  content: "\f532";
}

.bi-skip-forward-circle-fill::before {
  content: "\f533";
}

.bi-skip-forward-circle::before {
  content: "\f534";
}

.bi-skip-forward-fill::before {
  content: "\f535";
}

.bi-skip-forward::before {
  content: "\f536";
}

.bi-skip-start-btn-fill::before {
  content: "\f537";
}

.bi-skip-start-btn::before {
  content: "\f538";
}

.bi-skip-start-circle-fill::before {
  content: "\f539";
}

.bi-skip-start-circle::before {
  content: "\f53a";
}

.bi-skip-start-fill::before {
  content: "\f53b";
}

.bi-skip-start::before {
  content: "\f53c";
}

.bi-slack::before {
  content: "\f53d";
}

.bi-slash-circle-fill::before {
  content: "\f53e";
}

.bi-slash-circle::before {
  content: "\f53f";
}

.bi-slash-square-fill::before {
  content: "\f540";
}

.bi-slash-square::before {
  content: "\f541";
}

.bi-slash::before {
  content: "\f542";
}

.bi-sliders::before {
  content: "\f543";
}

.bi-smartwatch::before {
  content: "\f544";
}

.bi-sort-alpha-down-alt::before {
  content: "\f545";
}

.bi-sort-alpha-down::before {
  content: "\f546";
}

.bi-sort-alpha-up-alt::before {
  content: "\f547";
}

.bi-sort-alpha-up::before {
  content: "\f548";
}

.bi-sort-down-alt::before {
  content: "\f549";
}

.bi-sort-down::before {
  content: "\f54a";
}

.bi-sort-numeric-down-alt::before {
  content: "\f54b";
}

.bi-sort-numeric-down::before {
  content: "\f54c";
}

.bi-sort-numeric-up-alt::before {
  content: "\f54d";
}

.bi-sort-numeric-up::before {
  content: "\f54e";
}

.bi-sort-up-alt::before {
  content: "\f54f";
}

.bi-sort-up::before {
  content: "\f550";
}

.bi-soundwave::before {
  content: "\f551";
}

.bi-speaker-fill::before {
  content: "\f552";
}

.bi-speaker::before {
  content: "\f553";
}

.bi-speedometer::before {
  content: "\f554";
}

.bi-speedometer2::before {
  content: "\f555";
}

.bi-spellcheck::before {
  content: "\f556";
}

.bi-square-fill::before {
  content: "\f557";
}

.bi-square-half::before {
  content: "\f558";
}

.bi-square::before {
  content: "\f559";
}

.bi-stack::before {
  content: "\f55a";
}

.bi-star-fill::before {
  content: "\f55b";
}

.bi-star-half::before {
  content: "\f55c";
}

.bi-star::before {
  content: "\f55d";
}

.bi-stickies-fill::before {
  content: "\f55e";
}

.bi-stickies::before {
  content: "\f55f";
}

.bi-sticky-fill::before {
  content: "\f560";
}

.bi-sticky::before {
  content: "\f561";
}

.bi-stop-btn-fill::before {
  content: "\f562";
}

.bi-stop-btn::before {
  content: "\f563";
}

.bi-stop-circle-fill::before {
  content: "\f564";
}

.bi-stop-circle::before {
  content: "\f565";
}

.bi-stop-fill::before {
  content: "\f566";
}

.bi-stop::before {
  content: "\f567";
}

.bi-stoplights-fill::before {
  content: "\f568";
}

.bi-stoplights::before {
  content: "\f569";
}

.bi-stopwatch-fill::before {
  content: "\f56a";
}

.bi-stopwatch::before {
  content: "\f56b";
}

.bi-subtract::before {
  content: "\f56c";
}

.bi-suit-club-fill::before {
  content: "\f56d";
}

.bi-suit-club::before {
  content: "\f56e";
}

.bi-suit-diamond-fill::before {
  content: "\f56f";
}

.bi-suit-diamond::before {
  content: "\f570";
}

.bi-suit-heart-fill::before {
  content: "\f571";
}

.bi-suit-heart::before {
  content: "\f572";
}

.bi-suit-spade-fill::before {
  content: "\f573";
}

.bi-suit-spade::before {
  content: "\f574";
}

.bi-sun::before {
  content: "\f575";
}

.bi-sunglasses::before {
  content: "\f576";
}

.bi-symmetry-horizontal::before {
  content: "\f577";
}

.bi-symmetry-vertical::before {
  content: "\f578";
}

.bi-table::before {
  content: "\f579";
}

.bi-tablet-fill::before {
  content: "\f57a";
}

.bi-tablet-landscape-fill::before {
  content: "\f57b";
}

.bi-tablet-landscape::before {
  content: "\f57c";
}

.bi-tablet::before {
  content: "\f57d";
}

.bi-tag-fill::before {
  content: "\f57e";
}

.bi-tag::before {
  content: "\f57f";
}

.bi-tags-fill::before {
  content: "\f580";
}

.bi-tags::before {
  content: "\f581";
}

.bi-telegram::before {
  content: "\f582";
}

.bi-telephone-fill::before {
  content: "\f583";
}

.bi-telephone-forward-fill::before {
  content: "\f584";
}

.bi-telephone-forward::before {
  content: "\f585";
}

.bi-telephone-inbound-fill::before {
  content: "\f586";
}

.bi-telephone-inbound::before {
  content: "\f587";
}

.bi-telephone-minus-fill::before {
  content: "\f588";
}

.bi-telephone-minus::before {
  content: "\f589";
}

.bi-telephone-outbound-fill::before {
  content: "\f58a";
}

.bi-telephone-outbound::before {
  content: "\f58b";
}

.bi-telephone-plus-fill::before {
  content: "\f58c";
}

.bi-telephone-plus::before {
  content: "\f58d";
}

.bi-telephone-x-fill::before {
  content: "\f58e";
}

.bi-telephone-x::before {
  content: "\f58f";
}

.bi-telephone::before {
  content: "\f590";
}

.bi-terminal-fill::before {
  content: "\f591";
}

.bi-terminal::before {
  content: "\f592";
}

.bi-text-center::before {
  content: "\f593";
}

.bi-text-indent-left::before {
  content: "\f594";
}

.bi-text-indent-right::before {
  content: "\f595";
}

.bi-text-left::before {
  content: "\f596";
}

.bi-text-paragraph::before {
  content: "\f597";
}

.bi-text-right::before {
  content: "\f598";
}

.bi-textarea-resize::before {
  content: "\f599";
}

.bi-textarea-t::before {
  content: "\f59a";
}

.bi-textarea::before {
  content: "\f59b";
}

.bi-thermometer-half::before {
  content: "\f59c";
}

.bi-thermometer::before {
  content: "\f59d";
}

.bi-three-dots-vertical::before {
  content: "\f59e";
}

.bi-three-dots::before {
  content: "\f59f";
}

.bi-toggle-off::before {
  content: "\f5a0";
}

.bi-toggle-on::before {
  content: "\f5a1";
}

.bi-toggle2-off::before {
  content: "\f5a2";
}

.bi-toggle2-on::before {
  content: "\f5a3";
}

.bi-toggles::before {
  content: "\f5a4";
}

.bi-toggles2::before {
  content: "\f5a5";
}

.bi-tools::before {
  content: "\f5a6";
}

.bi-trash-fill::before {
  content: "\f5a7";
}

.bi-trash::before {
  content: "\f5a8";
}

.bi-trash2-fill::before {
  content: "\f5a9";
}

.bi-trash2::before {
  content: "\f5aa";
}

.bi-tree-fill::before {
  content: "\f5ab";
}

.bi-tree::before {
  content: "\f5ac";
}

.bi-triangle-fill::before {
  content: "\f5ad";
}

.bi-triangle-half::before {
  content: "\f5ae";
}

.bi-triangle::before {
  content: "\f5af";
}

.bi-trophy-fill::before {
  content: "\f5b0";
}

.bi-trophy::before {
  content: "\f5b1";
}

.bi-truck-flatbed::before {
  content: "\f5b2";
}

.bi-truck::before {
  content: "\f5b3";
}

.bi-tv-fill::before {
  content: "\f5b4";
}

.bi-tv::before {
  content: "\f5b5";
}

.bi-twitch::before {
  content: "\f5b6";
}

.bi-twitter::before {
  content: "\f5b7";
}

.bi-type-bold::before {
  content: "\f5b8";
}

.bi-type-h1::before {
  content: "\f5b9";
}

.bi-type-h2::before {
  content: "\f5ba";
}

.bi-type-h3::before {
  content: "\f5bb";
}

.bi-type-italic::before {
  content: "\f5bc";
}

.bi-type-strikethrough::before {
  content: "\f5bd";
}

.bi-type-underline::before {
  content: "\f5be";
}

.bi-type::before {
  content: "\f5bf";
}

.bi-ui-checks-grid::before {
  content: "\f5c0";
}

.bi-ui-checks::before {
  content: "\f5c1";
}

.bi-ui-radios-grid::before {
  content: "\f5c2";
}

.bi-ui-radios::before {
  content: "\f5c3";
}

.bi-union::before {
  content: "\f5c4";
}

.bi-unlock-fill::before {
  content: "\f5c5";
}

.bi-unlock::before {
  content: "\f5c6";
}

.bi-upc-scan::before {
  content: "\f5c7";
}

.bi-upc::before {
  content: "\f5c8";
}

.bi-upload::before {
  content: "\f5c9";
}

.bi-vector-pen::before {
  content: "\f5ca";
}

.bi-view-list::before {
  content: "\f5cb";
}

.bi-view-stacked::before {
  content: "\f5cc";
}

.bi-vinyl-fill::before {
  content: "\f5cd";
}

.bi-vinyl::before {
  content: "\f5ce";
}

.bi-voicemail::before {
  content: "\f5cf";
}

.bi-volume-down-fill::before {
  content: "\f5d0";
}

.bi-volume-down::before {
  content: "\f5d1";
}

.bi-volume-mute-fill::before {
  content: "\f5d2";
}

.bi-volume-mute::before {
  content: "\f5d3";
}

.bi-volume-off-fill::before {
  content: "\f5d4";
}

.bi-volume-off::before {
  content: "\f5d5";
}

.bi-volume-up-fill::before {
  content: "\f5d6";
}

.bi-volume-up::before {
  content: "\f5d7";
}

.bi-vr::before {
  content: "\f5d8";
}

.bi-wallet-fill::before {
  content: "\f5d9";
}

.bi-wallet::before {
  content: "\f5da";
}

.bi-wallet2::before {
  content: "\f5db";
}

.bi-watch::before {
  content: "\f5dc";
}

.bi-whatsapp::before {
  content: "\f5dd";
}

.bi-wifi-1::before {
  content: "\f5de";
}

.bi-wifi-2::before {
  content: "\f5df";
}

.bi-wifi-off::before {
  content: "\f5e0";
}

.bi-wifi::before {
  content: "\f5e1";
}

.bi-window-dock::before {
  content: "\f5e2";
}

.bi-window-sidebar::before {
  content: "\f5e3";
}

.bi-window::before {
  content: "\f5e4";
}

.bi-wrench::before {
  content: "\f5e5";
}

.bi-x-circle-fill::before {
  content: "\f5e6";
}

.bi-x-circle::before {
  content: "\f5e7";
}

.bi-x-diamond-fill::before {
  content: "\f5e8";
}

.bi-x-diamond::before {
  content: "\f5e9";
}

.bi-x-octagon-fill::before {
  content: "\f5ea";
}

.bi-x-octagon::before {
  content: "\f5eb";
}

.bi-x-square-fill::before {
  content: "\f5ec";
}

.bi-x-square::before {
  content: "\f5ed";
}

.bi-x::before {
  content: "\f5ee";
}

.bi-youtube::before {
  content: "\f5ef";
}

.bi-zoom-in::before {
  content: "\f5f0";
}

.bi-zoom-out::before {
  content: "\f5f1";
}

pre code.hljs {
  display: block;
  overflow-x: auto;
  padding: 1em;
}

code.hljs {
  padding: 3px 5px;
}

/*!
  Theme: Tokyo-night-Dark
  origin: https://github.com/enkia/tokyo-night-vscode-theme
  Description: Original highlight.js style
  Author: (c) Henri Vandersleyen <hvandersleyen@gmail.com>
  License: see project LICENSE
  Touched: 2022
*/
/*  Comment */
.hljs-meta,
.hljs-comment {
  color: #565f89;
}

/* Red */
/*INFO: This keyword, HTML elements, Regex group symbol, CSS units, Terminal Red */
.hljs-tag,
.hljs-doctag,
.hljs-selector-id,
.hljs-selector-class,
.hljs-regexp,
.hljs-template-tag,
.hljs-selector-pseudo,
.hljs-selector-attr,
.hljs-variable.language_,
.hljs-deletion {
  color: #f7768e;
}

/*Orange */
/*INFO: Number and Boolean constants, Language support constants */
.hljs-variable,
.hljs-template-variable,
.hljs-number,
.hljs-literal,
.hljs-type,
.hljs-params,
.hljs-link {
  color: #ff9e64;
}

/*  Yellow */
/* INFO:  	Function parameters, Regex character sets, Terminal Yellow */
.hljs-built_in,
.hljs-attribute {
  color: #e0af68;
}

/* cyan */
/* INFO: Language support functions, CSS HTML elements */
.hljs-selector-tag {
  color: #2ac3de;
}

/* light blue */
/* INFO: Object properties, Regex quantifiers and flags, Markdown headings, Terminal Cyan, Markdown code, Import/export keywords */
.hljs-keyword,
.hljs-title.function_,
.hljs-title,
.hljs-title.class_,
.hljs-title.class_.inherited__,
.hljs-subst,
.hljs-property {
  color: #7dcfff;
}

/*Green*/
/* INFO: Object literal keys, Markdown links, Terminal Green */
.hljs-selector-tag {
  color: #73daca;
}

/*Green(er) */
/* INFO: Strings, CSS class names */
.hljs-quote,
.hljs-string,
.hljs-symbol,
.hljs-bullet,
.hljs-addition {
  color: #9ece6a;
}

/* Blue */
/* INFO:  	Function names, CSS property names, Terminal Blue */
.hljs-code,
.hljs-formula,
.hljs-section {
  color: #7aa2f7;
}

/* Magenta */
/*INFO: Control Keywords, Storage Types, Regex symbols and operators, HTML Attributes, Terminal Magenta */
.hljs-name,
.hljs-keyword,
.hljs-operator,
.hljs-keyword,
.hljs-char.escape_,
.hljs-attr {
  color: #bb9af7;
}

/* white*/
/* INFO: Variables, Class names, Terminal White */
.hljs-punctuation {
  color: #c0caf5;
}

.hljs {
  background: #1a1b26;
  color: #9aa5ce;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

.hljs-copy-wrapper {
  position: relative;
  overflow: hidden;
}

.hljs-copy-wrapper:hover .hljs-copy-button, .hljs-copy-button:focus {
  transform: translateX(0);
}

.hljs-copy-button {
  position: absolute;
  transform: translateX(calc(100% + 1.125em));
  top: 1em;
  right: 1em;
  width: 2rem;
  height: 2rem;
  text-indent: -9999px;
  color: #fff;
  border-radius: 0.25rem;
  border: 1px solid rgba(255, 255, 255, 0.1333333333);
  background-color: #2d2b57;
  background-color: var(--hljs-theme-background);
  background-image: url('data:image/svg+xml;utf-8,<svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6 5C5.73478 5 5.48043 5.10536 5.29289 5.29289C5.10536 5.48043 5 5.73478 5 6V20C5 20.2652 5.10536 20.5196 5.29289 20.7071C5.48043 20.8946 5.73478 21 6 21H18C18.2652 21 18.5196 20.8946 18.7071 20.7071C18.8946 20.5196 19 20.2652 19 20V6C19 5.73478 18.8946 5.48043 18.7071 5.29289C18.5196 5.10536 18.2652 5 18 5H16C15.4477 5 15 4.55228 15 4C15 3.44772 15.4477 3 16 3H18C18.7956 3 19.5587 3.31607 20.1213 3.87868C20.6839 4.44129 21 5.20435 21 6V20C21 20.7957 20.6839 21.5587 20.1213 22.1213C19.5587 22.6839 18.7957 23 18 23H6C5.20435 23 4.44129 22.6839 3.87868 22.1213C3.31607 21.5587 3 20.7957 3 20V6C3 5.20435 3.31607 4.44129 3.87868 3.87868C4.44129 3.31607 5.20435 3 6 3H8C8.55228 3 9 3.44772 9 4C9 4.55228 8.55228 5 8 5H6Z" fill="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M7 3C7 1.89543 7.89543 1 9 1H15C16.1046 1 17 1.89543 17 3V5C17 6.10457 16.1046 7 15 7H9C7.89543 7 7 6.10457 7 5V3ZM15 3H9V5H15V3Z" fill="white"/></svg>');
  background-repeat: no-repeat;
  background-position: center;
  transition: background-color 200ms ease, transform 200ms ease-out;
}

.hljs-copy-button:hover {
  border-color: rgba(255, 255, 255, 0.2666666667);
}

.hljs-copy-button:active {
  border-color: rgba(255, 255, 255, 0.4);
}

.hljs-copy-button[data-copied=true] {
  text-indent: 0;
  width: auto;
  background-image: none;
}

@media (prefers-reduced-motion) {
  .hljs-copy-button {
    transition: none;
  }
}
.hljs-copy-alert {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

#id-navigation-wrapper {
  position: fixed;
  width: 100%;
  z-index: 999;
  text-transform: uppercase;
}
#id-navigation-wrapper .container {
  position: relative;
}

.id-main-navigation {
  display: none;
  float: right;
  font-family: "Aestetico", sans-serif;
  font-size: 16px;
}
.id-main-navigation ul {
  margin: 0px;
  float: right;
}
.id-main-navigation ul ul.level_2 {
  display: none;
  position: absolute;
  width: 400px;
  background-color: #f6921d;
}
.id-main-navigation ul ul.level_2 li {
  width: 100%;
}
.id-main-navigation ul ul.level_2 li:hover {
  background-color: #d77809;
}
.id-main-navigation ul ul.level_2 li a {
  width: 100%;
  padding: 10px 20px;
  display: block;
  text-decoration: none;
}
.id-main-navigation ul ul.level_2 li.active {
  padding: 10px 20px;
  background-color: #d77809;
  color: #f05923;
}
.id-main-navigation ul li {
  display: inline-block;
  transition-duration: 0.3s;
  position: relative;
}
.id-main-navigation ul li a {
  color: #000;
  padding: 25px 15px;
  display: block;
  text-decoration: none;
  transition-duration: 0.2s;
}
.id-main-navigation ul li a span {
  position: relative;
  font-weight: 600;
}
.id-main-navigation ul li:hover a {
  color: #f05923;
}
.id-main-navigation ul li:hover a ul.level_2 {
  display: inherit;
  transition-property: display;
  transition-duration: 0.2s;
}
.id-main-navigation ul li strong.active {
  color: #f05923;
  padding: 25px 20px;
  background: none;
  font-weight: 600;
}

.shrink {
  background-color: #fff !important;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.13);
}
.shrink .id-main-navigation ul li a {
  padding: 15px 10px !important;
  transition-duration: 0.3s;
}
.shrink .id-mobile-menu {
  padding-top: 5px !important;
  padding-right: 15px !important;
  color: #fff;
}
.shrink .id-navigation {
  padding-top: 10px;
  padding-bottom: 10px;
}
.shrink .id-mobile-menu:hover {
  color: #f05923;
}
.shrink .cd-nav-trigger {
  transform: translateY(-10px);
  transition-duration: 0.3s;
}

.id-navigation {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.id-mobile-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 2.2rem;
  color: #000;
}
.id-mobile-menu:hover {
  color: #273033;
}

.id-logo {
  float: left;
  width: 250px;
  height: auto;
  transition-duration: 0.3s;
}

/*
ul.level_1 li:nth-child(4):hover::after {
height: 20px;
font-size: 0.8rem;
width: 20px;
text-align: center;
color: #fff;
border-radius: 50%;
background: orange;
content: "3";
right: -5px;
top: 10px;
position: absolute;
}
*/
.id-nav-mobile {
  display: block;
}

.cd-nav-trigger {
  top: 25px;
  right: 0;
  height: 44px;
  width: 44px;
  z-index: 5;
  /* image replacement */
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  display: block;
  cursor: pointer;
  position: absolute;
}

@media screen and (min-width: 992px) {
  .cd-nav-trigger {
    display: none;
  }
  .id-nav-mobile {
    display: none;
  }
}
.cd-nav-trigger .cd-icon {
  /* icon created in CSS */
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: inline-block;
  width: 18px;
  height: 3px;
  background-color: #fff;
  z-index: 10;
}

.cd-nav-trigger .cd-icon::before, .cd-nav-trigger .cd-icon:after {
  /* upper and lower lines of the menu icon */
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /* apply transition to transform property */
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
}

.cd-nav-trigger .cd-icon::before {
  -webkit-transform: translateY(-6px) rotate(0deg);
  -moz-transform: translateY(-6px) rotate(0deg);
  -ms-transform: translateY(-6px) rotate(0deg);
  -o-transform: translateY(-6px) rotate(0deg);
  transform: translateY(-6px) rotate(0deg);
}

.cd-nav-trigger .cd-icon::after {
  -webkit-transform: translateY(6px) rotate(0deg);
  -moz-transform: translateY(6px) rotate(0deg);
  -ms-transform: translateY(6px) rotate(0deg);
  -o-transform: translateY(6px) rotate(0deg);
  transform: translateY(6px) rotate(0deg);
}

.cd-nav-trigger::before, .cd-nav-trigger::after {
  /* 2 rounded colored backgrounds for the menu icon */
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  transition-property: transform;
}

.cd-nav-trigger::before {
  content: "";
  background-color: #f05923;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-delay: 0.4s;
  -moz-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.cd-nav-trigger::after {
  background-color: #ffb441;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition-duration: 0s;
  -moz-transition-duration: 0s;
  transition-duration: 0s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
}

.cd-nav-trigger.close-nav::before {
  /* user clicks on the .cd-nav-trigger element - 1st rounded background disappears */
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.cd-nav-trigger.close-nav::after {
  /* user clicks on the .cd-nav-trigger element - 2nd rounded background appears */
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-delay: 0.4s;
  -moz-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.cd-nav-trigger.close-nav .cd-icon {
  /* user clicks on the .cd-nav-trigger element - transform the icon */
  background-color: rgba(255, 255, 255, 0);
}

.cd-nav-trigger.close-nav .cd-icon::before, .cd-nav-trigger.close-nav .cd-icon::after {
  background-color: white;
}

.cd-nav-trigger.close-nav .cd-icon::before {
  -webkit-transform: translateY(0) rotate(45deg);
  -moz-transform: translateY(0) rotate(45deg);
  -ms-transform: translateY(0) rotate(45deg);
  -o-transform: translateY(0) rotate(45deg);
  transform: translateY(0) rotate(45deg);
}

.cd-nav-trigger.close-nav .cd-icon::after {
  -webkit-transform: translateY(0) rotate(-45deg);
  -moz-transform: translateY(0) rotate(-45deg);
  -ms-transform: translateY(0) rotate(-45deg);
  -o-transform: translateY(0) rotate(-45deg);
  transform: translateY(0) rotate(-45deg);
}

.cd-primary-nav {
  /* by default it's hidden */
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 80px 5%;
  z-index: 3;
  background-color: #091d23;
  overflow: auto;
  /* this fixes the buggy scrolling on webkit browsers - mobile devices only - when overflow property is applied */
  -webkit-overflow-scrolling: touch;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cd-primary-nav li {
  margin: 5px auto;
  text-align: center;
  list-style: none;
  font-size: 3rem;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  width: 100%;
}
.cd-primary-nav li .active {
  color: #f05923;
}

.cd-primary-nav a {
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: color 0.2s;
  -moz-transition: color 0.2s;
  transition: color 0.2s;
}
.cd-primary-nav a:hover {
  color: #f05923;
}

.no-touch .cd-primary-nav a:hover {
  color: #ffffff;
}

.cd-primary-nav.fade-in {
  /* navigation visible at the end of the circle animation */
  visibility: visible;
  opacity: 1;
}

.cd-overlay-nav, .cd-overlay-content {
  /* containers of the 2 main rounded backgrounds - these containers are used to position the rounded bgs behind the menu icon */
  position: fixed;
  top: 18px;
  right: 5%;
  height: 4px;
  width: 4px;
  -webkit-transform: translateX(-20px) translateY(20px);
  -moz-transform: translateX(-20px) translateY(20px);
  -ms-transform: translateX(-20px) translateY(20px);
  -o-transform: translateX(-20px) translateY(20px);
  transform: translateX(-20px) translateY(20px);
}

.cd-overlay-nav span, .cd-overlay-content span {
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.cd-overlay-nav.is-hidden, .cd-overlay-content.is-hidden {
  /* background fades out at the end of the animation */
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
}

.cd-overlay-nav {
  /* main rounded colored bg 1 */
  z-index: 2;
}

.cd-overlay-nav span {
  background-color: #091d23;
}

.cd-overlay-content {
  /* main rounded colored bg 2 */
  z-index: 4;
}

.cd-overlay-content span {
  background-color: #ffb441;
}

.cd-content {
  /* just some dummy content */
  padding: 80px 0;
  width: 90%;
  max-width: 768px;
  margin: 0 auto;
  z-index: 1;
}

.cd-content .cd-intro {
  height: 200px;
  padding-top: 4.6em;
}

.cd-content h1 {
  text-align: center;
  font-size: 20px;
  font-size: 1.25rem;
}

.cd-content p {
  line-height: 1.5;
  color: #a76500;
}

@media only screen and (min-width: 768px) {
  .cd-content .cd-intro {
    height: 250px;
    padding-top: 6em;
  }
  .cd-content h1 {
    font-size: 28px;
    font-size: 1.75rem;
  }
  .cd-content p {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.8;
  }
}
.inner img, button, a {
  position: relative;
  z-index: 1;
}

.project-img {
  max-width: 1200px;
  margin: 0 auto 50px auto;
}

.header--start {
  min-height: 450px;
}
.header--start .container {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  transform: translateY(-45%);
}
.header--start .inner {
  position: relative;
  background-size: 100%;
  min-height: 400px;
}
.header--start .inner .shape__triangle {
  position: absolute;
  top: 40px;
  right: 0;
  width: 80px;
}
@media screen and (min-width: 992px) {
  .header--start .inner .shape__triangle {
    right: 20%;
    animation: MoveUpDown 1s linear infinite;
  }
}
.header--start .inner .shape__circle {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 80px;
  opacity: 0.6;
}
.header--start .inner .shape__hatching--thick {
  width: 80px;
  position: absolute;
  right: 0;
  bottom: 5%;
}
@media screen and (min-width: 992px) {
  .header--start .inner .shape__hatching--thick {
    width: 110px;
  }
}
.header--start .inner .shape__hatching--thin {
  width: 120px;
  position: absolute;
  left: 0;
  top: 10%;
  opacity: 0.4;
}
.header--start .inner .shape__line {
  display: none;
  width: 200px;
  position: absolute;
  left: 10%;
  bottom: 3%;
}
@media screen and (min-width: 992px) {
  .header--start .inner .shape__line {
    display: block;
  }
}
.header--start .header-heading {
  z-index: 9;
}
.header--start h1 {
  font-size: 1.8rem;
  font-weight: 700;
}
@media screen and (min-width: 576px) {
  .header--start h1 {
    font-size: 2.6rem;
  }
}
@media screen and (min-width: 1200px) {
  .header--start h1 {
    font-size: 3.2rem;
  }
}
.header--start p {
  color: #283134;
}
.header--start span {
  color: #f05923;
}

.header--sub {
  background: linear-gradient(0deg, #eaeaea 0, #f5f5f5 46%, #fff 100%);
}
.header--sub .container {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  transform: translateY(-20%);
}

.header--sub {
  min-height: 450px;
  position: relative;
  background-position: bottom right;
}
.header--sub .inner h1 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 2.5rem;
  color: #283134;
}
.header--sub .inner h1 span {
  text-transform: none;
  color: #f05923;
  font-weight: 500;
  font-size: 0.8rem;
}
.header--sub .inner p {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.8rem;
  display: block;
}
.header--sub .inner .image-right {
  display: none;
}

@media screen and (min-width: 992px) {
  .header--start, .header--sub {
    min-height: 620px;
  }
  .header--start .inner .image-right, .header--sub .inner .image-right {
    display: flex;
    justify-content: flex-end;
  }
  .header--sub .inner h1 {
    font-size: 3rem;
  }
}
.id-header h1 {
  color: #fff;
  line-height: 3.4rem;
  font-weight: 400;
}
.id-header h1 em {
  font-family: "Pacifico", cursive;
  font-size: 3rem;
  color: #f6921d;
}

html {
  font-size: 18px;
}

body {
  font-family: "Aestetico", sans-serif;
  font-weight: 400;
  font-size: 1rem;
}

h1 {
  color: #283134;
  line-height: 1.2;
}

h1, h2, h3 {
  font-family: "Aestetico", sans-serif;
  font-weight: 600;
  line-height: 1.2;
}

h4 {
  font-size: 1.4rem;
  font-weight: 500;
  margin-top: 0;
}

a {
  color: #f05923;
  text-decoration: none;
  z-index: 0;
}
a:hover {
  color: #a2330b;
}

small {
  font-size: 0.8rem;
  line-height: normal;
}

figure {
  margin: 0;
}
figure figcaption {
  padding-top: 20px;
  font-size: 0.9rem;
  color: #9a9a9a;
}

strong {
  font-weight: 700;
}

p {
  line-height: 1.5rem;
}

.block--visible {
  overflow: visible;
}

.list li span {
  color: #f05923;
}
.list li:before {
  display: inline-block;
  content: "";
  display: inline-block;
  content: "";
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  height: 14px;
  width: 14px;
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23f05923" class="bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/></svg>');
  background-repeat: no-repeat;
  background-size: 1rem 1rem;
  margin-right: 10px;
}
.list p {
  margin-bottom: 0;
}

blockquote {
  position: relative;
}
blockquote:before {
  display: inline-block;
  content: "";
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  height: 50px;
  width: 50px;
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="%23283134" class="bi bi-quotations" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M6 17h3l2-4V7H5v6h3zm8 0h3l2-4V7h-6v6h3z"/></svg>');
  position: absolute;
  left: 0;
  top: 0;
}
blockquote p {
  line-height: 2.2rem;
  max-width: 80%;
  margin-left: 70px;
  font-size: 1.8rem;
}
blockquote small {
  display: block;
  margin-top: 10px;
}

.w-100 {
  width: 100%;
}
.w-100 img {
  width: 100%;
}

.text-primary {
  color: #f05923 !important;
}

.text-center {
  text-align: center;
  margin: auto;
}

.text-dark {
  color: #000;
}

.text-white {
  color: #fff;
}

.highlight h2::after {
  margin-top: 10px;
  content: "";
  width: 60px;
  height: 5px;
  display: block;
  background-color: #f05923;
}

.gap-10 {
  margin-top: 10%;
}

.gap-20 {
  margin-top: 20%;
}

.gap-30 {
  margin-top: 30%;
}

.gap-40 {
  margin-top: 40%;
}

.gap-50 {
  margin-top: 50%;
}

.gap-60 {
  margin-top: 60%;
}

.gap-70 {
  margin-top: 70%;
}

.gt-80 {
  margin-top: 40px;
}

.gb-80 {
  margin-bottom: 40px;
}

.py-default {
  padding-top: 80px;
  padding-bottom: 80px;
}

.bg-white {
  background-color: #fff;
}

.bg-grey {
  background-color: #edf1f2;
  padding: 20px;
}

.bg-dark {
  background-color: #283134;
}

/*========================Header======================*/
/*========================Main-Content======================*/
/*-------Buttons*/
button {
  cursor: pointer;
  border: none;
}

.btn-lg {
  border-radius: 25px !important;
  font-size: 1.25rem;
}
.btn-lg:hover {
  box-shadow: none;
  -webkit-box-shadow: none;
}

.shadowed {
  box-shadow: 8px 5px 20px 1px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
.shadowed:hover {
  box-shadow: none;
}

.btn--submit {
  background-color: #f05923;
  color: #fff;
  padding: 0.8125rem;
  transition: background-color 0.3s;
}
.btn--submit:hover {
  color: #fff;
  background-color: #f26b3b;
}

.btn--primary {
  background: none;
  display: inline-block;
  margin-top: 20px;
  padding: 0.8125rem;
  border-left: 2px solid #f05923;
  border-bottom: 2px solid #f05923;
  color: #f05923;
}
.btn--primary:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 2px;
  transition-delay: 0;
  transition-duration: 0.2s;
  background: #f05923;
  bottom: 0;
  right: 0;
}
.btn--primary:before {
  content: "";
  position: absolute;
  transition-delay: 0.2s;
  transition-duration: 0.2s;
  width: 100%;
  height: 2px;
  background: #f05923;
  top: 0;
  right: 0;
}
.btn--primary:hover {
  color: #f05923;
}
.btn--primary:hover:after {
  transition-delay: 0.2s;
  height: 0;
  width: 2px;
}
.btn--primary:hover:before {
  transition-delay: 0s;
  transition-duration: 0.2s;
  width: 0;
  height: 2px;
}
.btn--primary:hover span {
  transition-delay: 0.3s;
  transform: translateX(8px);
  transition-duration: 0.2s;
  left: 8px;
}
.btn--primary span {
  display: inline-block;
  transition-duration: 0.2s;
  transform: translateX(0);
  font-size: 0.9rem;
  margin-left: 10px;
  left: -20px;
}

.btn--secondary {
  background: none;
  display: inline-block;
  margin-top: 20px;
  padding: 0.8125rem;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  color: #fff;
}
.btn--secondary:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 2px;
  transition-delay: 0;
  transition-duration: 0.2s;
  background: #fff;
  bottom: 0;
  right: 0;
}
.btn--secondary:before {
  content: "";
  position: absolute;
  transition-delay: 0.2s;
  transition-duration: 0.2s;
  width: 100%;
  height: 2px;
  background: #fff;
  top: 0;
  right: 0;
}
.btn--secondary:hover {
  color: #fff;
}
.btn--secondary:hover:after {
  transition-delay: 0.2s;
  height: 0;
  width: 2px;
}
.btn--secondary:hover:before {
  transition-delay: 0s;
  transition-duration: 0.2s;
  width: 0;
  height: 2px;
}
.btn--secondary:hover span {
  transition-delay: 0.3s;
  transform: translateX(8px);
  transition-duration: 0.2s;
  left: 8px;
}
.btn--secondary span {
  display: inline-block;
  transition-duration: 0.2s;
  transform: translateX(0);
  font-size: 0.9rem;
  margin-left: 10px;
  left: -20px;
}

.image--grayscale {
  filter: grayscale(1);
  opacity: 0.5;
  transition: all 0.3s;
}
.image--grayscale:hover {
  filter: none;
  opacity: 1;
}
.image__fullwidth {
  position: relative;
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 992px) {
  .image__fullwidth {
    position: absolute;
    width: 45%;
    max-width: 1000px;
    padding: 0;
  }
}
.image__fullwidth--left {
  left: 0;
}
.image__fullwidth--left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: right;
}
.image__fullwidth--right {
  right: 0;
}
.image__fullwidth--right img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
}
.image__fullwidth .image_container {
  height: 100%;
}

.container {
  max-width: 1300px;
}

.container-fluid {
  position: relative;
}

/*------Sections*/
#rocket-container,
#cart-container,
#chart-container {
  padding: 20px;
  z-index: 10;
}
#rocket-container:hover i,
#cart-container:hover i,
#chart-container:hover i {
  transform: translateX(5px);
  transition-duration: 0.3s;
}

.team-container h2 {
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0;
}
.team-container .subheading {
  margin-top: 5px;
  color: #cacaca;
  font-weight: 500;
}
.team-container a {
  color: #cacaca;
  transition-property: color;
  transition-duration: 0.3s;
}
.team-container a:hover {
  color: #f05923;
}
.team-container i {
  font-size: 2rem;
  margin-right: 15px;
}

.id-customer {
  background-color: #283134;
}
.id-customer .inner {
  padding-top: 15px;
}

.id-cta {
  margin: 80px 0 0 0;
  background-image: linear-gradient(to left top, #f05923, #f26c24, #f37d27, #f48d2d, #f49c36);
  color: #fff;
}
.id-cta .inner {
  padding: 80px 0;
  margin: auto;
}

#latest-projects .row {
  max-width: 1300px;
  margin: auto;
  padding-top: 40px;
}
#latest-projects .cs:last-child {
  display: none;
}
@media screen and (min-width: 768px) {
  #latest-projects .cs:last-child {
    display: block;
  }
}

#about {
  padding-top: 80px;
  padding-bottom: 80px;
  background: #edf1f2;
}
@media screen and (min-width: 768px) {
  #about {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}
#about h1, #about h3 {
  margin: 0;
}
#about h2 {
  color: #283134;
  margin-top: 0;
  font-size: 1.4rem;
}
@media screen and (min-width: 768px) {
  #about h2 {
    font-size: 2.5rem;
  }
}
#about h3 {
  font-size: 0.9rem;
  color: #eb5b27;
}

.services .services__service {
  display: flex;
  align-items: center;
  background: url("https://www.toptal.com/designers/subtlepatterns/patterns/papyrus.png");
  padding: 15px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  box-shadow: 8px 5px 20px 1px rgba(0, 0, 0, 0.15);
}
.services .services__service:hover .services__image {
  fill: #f05923;
  transition: all 0.3s ease;
}
.services a {
  color: #000;
}
.services .services__image {
  max-width: 50px;
  margin-right: 20px;
}

#customers {
  padding-top: 80px;
  padding-bottom: 80px;
  background: #283134;
}
#customers h2 {
  font-size: 1.5rem;
  margin-bottom: 60px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}

.customers-wrapper {
  max-width: 920px;
  margin: auto;
  display: flex;
  align-items: center;
}
.customers-wrapper__showmore {
  margin-top: 40px;
  font-size: 0.9rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.customers-wrapper__showmore--active i {
  transform: rotate(180deg);
}
.customers-wrapper--full {
  max-height: 0px;
  transition: max-height 0.8s ease;
  overflow: hidden;
  justify-content: center;
}
.customers-wrapper .ce_image {
  margin: 10px 0;
  text-align: center;
  opacity: 0.8;
}

.ce_accordion .toggler {
  font-weight: 700;
  cursor: pointer;
  outline: none;
}
.ce_accordion .toggler.active span:before {
  transform: rotate(90deg);
}
.ce_accordion p {
  font-size: 0.8rem;
  line-height: 1.5em;
  padding-left: 20px;
}

#project-highlight {
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  padding-top: 80px;
}
#project-highlight h2 {
  color: #283134;
  margin-top: 0;
  font-size: 2rem;
}
#project-highlight .project-highlight__textcontent {
  font-size: 1.2rem;
  line-height: normal;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.headline--label {
  color: #283134;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  margin-bottom: 15px;
}
.headline--label:after {
  content: "";
  height: 1px;
  width: 20%;
  background: #283134;
  display: inline-block;
  margin-left: 20px;
}

#blog {
  padding-top: 40px;
  padding-bottom: 40px;
}
#blog h2 {
  font-size: 2.5rem;
}
#blog .info {
  display: flex;
  font-size: 0.9rem;
  margin-bottom: 0;
}
#blog .info i {
  display: flex;
  align-items: center;
}

.newsreader .ce_text {
  max-width: 700px;
  margin: auto;
}
.newsreader h3 {
  font-size: 1.4rem;
  font-weight: 500;
  color: #f05923;
}
.newsreader ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.newsreader li {
  margin-bottom: 1rem;
}
.newsreader p, .newsreader ul {
  margin-bottom: 1.5rem;
}

input:focus {
  box-shadow: none;
  outline: none;
}

input:-webkit-autofill {
  -webkit-text-fill-color: none !important;
}

.fixed-bg-image {
  background-position: center;
  background-attachment: fixed;
  background-image: url("/files/images/Blog/newsletter-smartphone.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 300px;
}

.h-square {
  min-height: 30vw;
}

@media screen and (min-width: 992px) {
  .mh-700 {
    min-height: 700px;
  }
}

.object-fit-image {
  object-fit: cover;
}

.swiper-wrapper {
  display: flex;
  align-items: center;
}

.swiper-button-next {
  display: inline-block;
  content: "";
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  height: 50px;
  width: 50px;
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23fff" class="bi bi-chevron-compact-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"/></svg>');
}

.swiper-button-prev {
  visibility: hidden;
  display: inline-block;
  content: "";
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  height: 50px;
  width: 50px;
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23fff" class="bi bi-chevron-compact-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"/></svg>');
}

.swiper-slide {
  text-align: center;
}

.id-form_small input[type=text], .id-form_small input[type=email], .id-form_small textarea {
  background: #fff;
  border: solid 1px #313131;
  width: 100%;
  color: #313131;
  padding: 15px;
  margin-bottom: 15px;
}
.id-form_small input[type=text]:focus, .id-form_small input[type=email]:focus, .id-form_small textarea:focus {
  border: solid 1px #f05923;
  transition: 0.4s;
  box-shadow: none;
}

/* --------------------------
Form Styling
--------------------------- */
.contact-form {
  width: 100%;
  overflow: visible;
}
.contact-form form {
  margin-top: 20px;
  padding: 20px 0;
}
.contact-form .inner {
  margin: auto;
  color: #000;
  display: flex;
  flex-direction: column;
}
.contact-form .inner h1 {
  color: #fff;
  background: #f87309;
  padding: 20px;
}

.contact-form small {
  line-height: auto;
}

.form-control {
  border: none;
  color: #000;
  font-style: italic;
  border-bottom: solid 1.5px #dedede;
  border-radius: 0px;
  background: none;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
}

.form-control:focus {
  background: none;
  color: #000;
  border-color: #f87309;
  box-shadow: none;
}

.form-group {
  position: relative;
  margin-bottom: 2.2rem;
  margin-top: 1rem;
}

.floating-label {
  color: #999;
  font-size: 0.9rem;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

input:focus ~ .floating-label, input:valid ~ .floating-label {
  top: -25px;
  font-size: 14px;
  color: #f87309;
}

textarea:focus ~ .floating-label, textarea:valid ~ .floating-label {
  top: -25px;
  font-size: 14px;
  color: #f87309;
}

/*Toggle Switch */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  max-width: 75px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 15px;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #f87309;
}

input:focus + .slider {
  box-shadow: 0 0 1px #f87309;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

h2.typewriter {
  font-style: italic;
}
h2.typewriter span {
  color: #f05923 !important;
  font-size: 2rem;
}

#blog .post {
  margin-bottom: 40px;
}
#blog .post .inner {
  border: solid 2px #f3f3f3;
  background: #fff;
  transition-duration: 0.6s;
  position: relative;
}
#blog .post .inner:hover {
  border: solid 2px #f05923;
}
#blog .post .inner .blog-teaser-content {
  padding: 20px;
}
#blog .post .inner .blog-teaser-content h3 {
  margin-top: 0;
  font-size: 1.6rem;
}
#blog .post .inner .blog-teaser-content h4 {
  margin-top: 0;
  font-size: 1rem;
  color: #f05923;
}
#blog .post .inner .blog-teaser-content .image_container {
  max-height: 250px;
  overflow-y: hidden;
  margin-bottom: 20px;
}
#blog .post .inner .blog-teaser-content a {
  color: #1c1e24;
}
#blog .post .inner .blog-teaser-content .btn {
  position: absolute;
  bottom: 30px;
}

.cs__wrapper {
  max-width: 1140px;
}

.cs-teaser {
  position: relative;
  margin-bottom: 80px;
  overflow: visible;
  /*.cs-overlay {
  	position: absolute;
  	bottom: 0;
  	width: 100%;
  	height: 100%;
  	//background: rgba($color-tertiary, 0.7);
  }*/
}
.cs-teaser .cs-inner {
  /*position: relative;;
  width: 100%;
  height: 100%;
  padding: 20px;*/
}
.cs-teaser .cs-data {
  position: absolute;
  top: 10px;
  font-weight: 800;
  font-size: 1rem;
  text-decoration: underline;
}
.cs-teaser .cs-desc {
  /*position: absolute;
  bottom: -20px;
  padding: 20px;
  width: 90%;
  left: 0;
  right: 0;
  margin: auto;
  background: #fff;
  box-shadow: 10px 10px 23px -7px rgba(0,0,0,0.52);*/
  transition-duration: 0.3s;
  margin-top: 25px;
}
.cs-teaser .cs-desc h3 {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 500;
}
.cs-teaser .cs-desc a {
  color: #000;
}
.cs-teaser .cs-desc p {
  color: #000;
  display: flex;
  font-size: 1.6rem;
  /*font-weight: 700;*/
  line-height: normal;
  margin-bottom: 25px;
}
.cs-teaser .cs-desc p:before {
  height: 65px;
  margin-right: 10px;
  width: 7px;
  /*content: "";*/
  background: #f05923;
  display: flex;
}
.cs-teaser figure {
  overflow: hidden;
}
.cs-teaser img {
  transition: transform 0.6s ease-out;
}

.cs-teaser:hover {
  transition: all 0.3s ease-out;
}
.cs-teaser:hover .cs-desc {
  bottom: 0;
}
.cs-teaser:hover .cs-overlay {
  transition-duration: 0.3s;
  /*background: rgba($color-primary, 0.8);*/
}
.cs-teaser:hover img {
  transition: transform 0.6s ease-out;
  transform: scale(1.1);
}

.briefing {
  border-bottom: solid 1px;
  text-align: center;
  padding-bottom: 20px;
}
.briefing h2 {
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
  font-weight: 700;
  color: #cacaca;
}
.briefing a {
  color: #f05923;
}

.projectCase .projectCase__fullwidth .projectCase__textbox {
  max-width: 80%;
  padding: 20px;
  margin: auto;
}

.hal-computers-video {
  overflow: visible;
  padding: 20px;
  box-shadow: -18px 10px 30px -11px rgba(0, 0, 0, 0.36);
  border: solid 1px #eaeaea;
  margin-bottom: 80px;
}

.itsmedia_background {
  background: url("/files/images/Projekte/itsmedia_background.jpg") no-repeat;
  background-size: cover;
}

.liebls_background {
  background: #071f2b;
}

.case-new .briefing {
  text-align: left;
  border-bottom: none;
  padding-bottom: 0;
  font-weight: 700;
  font-size: 1.2rem;
}

.case-new .overview p {
  margin-bottom: 5px;
}

.case__muehlbauer__bg {
  background: url(/files/images/Projekte/cs_muehlbauer-steuerberater_background.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.case__osteopathie__bg {
  background: url(/files/images/Projekte/cs_osteopathie-breu_background.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.case__video {
  box-shadow: #161c24 0 0 80px 0;
  border: solid 4px #d0d0d0;
  border-radius: 10px;
}

.case__video figure {
  display: flex;
}

.header--sub .inner h1 {
  font-size: 2.2rem;
}
@media screen and (min-width: 576px) {
  .header--sub .inner h1 {
    font-size: 2.5rem;
  }
}

blockquote p {
  line-height: 1.8rem;
  max-width: 100%;
  margin-left: 36px;
  font-size: 1.2rem;
  font-weight: bold;
}
@media screen and (min-width: 576px) {
  blockquote p {
    line-height: 2.2rem;
    max-width: 80%;
    margin-left: 70px;
    font-size: 1.8rem;
  }
}

blockquote:before {
  height: 30px;
  width: 30px;
}
@media screen and (min-width: 576px) {
  blockquote:before {
    height: 50px;
    width: 50px;
  }
}

.mod_recommendationlist {
  display: flex;
  padding: 30px;
  background: rgba(40, 49, 52, 0.8);
  align-items: center;
  color: #fff;
  border-radius: 40px;
}
.mod_recommendationlist .quote {
  position: relative;
  padding-left: 10px;
}
@media screen and (min-width: 576px) {
  .mod_recommendationlist .quote {
    padding-left: 70px;
  }
}
.mod_recommendationlist .quote:before {
  display: inline-block;
  content: "";
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  height: 110px;
  width: 110px;
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="%23f3f3f3" class="bi bi-quotations" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M6 17h3l2-4V7H5v6h3zm8 0h3l2-4V7h-6v6h3z"/></svg>');
  position: absolute;
  left: -10px;
  top: -30px;
  opacity: 0.6;
}
.mod_recommendationlist .quote p {
  line-height: 1.5;
}
.mod_recommendationlist .quote h4, .mod_recommendationlist .quote .author {
  font-weight: bold;
}
.mod_recommendationlist .quote figure {
  margin-right: 15px;
}

.swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}

/*========================Footer======================*/
.footer {
  background: #edf1f2;
  color: #313131;
}
.footer h3 {
  color: #f05923;
}
.footer a {
  color: #313131;
}
.footer a:hover {
  color: #f05923;
}

.id-copyright {
  padding: 15px 0;
  border-top: solid 1px #fff;
  font-size: 0.8rem;
  text-align: center;
}
@media screen and (min-width: 576px) {
  .id-copyright {
    text-align: unset;
  }
}

.id-social-icons .inner {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}
.id-social-icons .inner i {
  padding-right: 20px;
}

.footer__inner {
  padding: 80px 15px;
}

/* --------------------------
Media-Queries
--------------------------- */
@media only screen and (min-width: 992px) {
  ::-moz-selection { /* Code for Firefox */
    color: #fff;
    background: #f6921d;
  }
  ::selection {
    color: #fff;
    background: #f6921d;
  }
  /* width */
  ::-webkit-scrollbar {
    width: 15px;
    height: 20px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #f05923;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #f0561f;
  }
}
@media screen and (min-width: 992px) {
  .id-mobile-menu {
    display: none;
  }
  .id-main-navigation {
    display: block;
  }
  .gb-80 {
    margin-bottom: 80px;
  }
  .gt-80 {
    margin-top: 80px;
  }
  .header--start {
    background-size: 500px auto;
  }
}
@media screen and (min-width: 576px) {
  .shrink .id-navigation {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .id-navigation {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .id-featured-content .text-container {
    padding: 30px 60px;
  }
  .nl-sub-box {
    height: 210px;
  }
  .nl-sub-box .container .sub-wrapper {
    overflow: visible;
    max-width: 80%;
    margin: auto;
  }
  .nl-sub-box .container .sub-wrapper .formbody {
    flex-wrap: nowrap;
  }
  .nl-sub-box .container .sub-wrapper .formbody input {
    width: 75%;
    margin-bottom: 0;
  }
  .nl-sub-box {
    display: block;
  }
  .header--start, .header--sub {
    min-height: 550px;
  }
}
@media screen and (min-width: 768px) {
  .header--start .inner p, .header--sub .inner p {
    font-size: 1.2rem;
    line-height: 2rem;
  }
}
@media screen and (min-width: 992px) {
  .header--start {
    min-height: 500px;
  }
}
@media screen and (min-width: 1200px) {
  .header--start {
    background-size: 100%;
  }
  #services h2 {
    font-size: 50px;
  }
  .header--start .container {
    transform: translateY(-45%);
  }
}