.inner img, button, a {
	position: relative;
	z-index: 1;
}

//projekt mockup

.project-img {
  max-width: 1200px;
  margin: 0 auto 50px auto;
}


//=======================> Startseite Header
.header--start {
	min-height: 450px;
	.container {
		position: absolute;
		left: 0;
		right: 0;
		margin: auto;
		top: 50%;
		transform: translateY(-45%);
	}
	.inner {
		position: relative;
		background-size: 100%;
		min-height: 400px;
		.shape__triangle {
			@include responsive (landscape) {
				right: 20%;
				animation: MoveUpDown 1s linear infinite;
			}
			position: absolute;
			top: 40px;
			right: 0;
			width: 80px;
		}
		.shape__circle {
			position: absolute;
			bottom:0;
			left: 0;
			right: 0;
			margin: auto;
			width: 80px;
			opacity: 0.6;
		}
		.shape__hatching--thick {
			@include responsive (landscape) {
				width: 110px;
			}
			width: 80px;
			position: absolute;
			right:0;
			bottom: 5%;
		}
		.shape__hatching--thin {
			width: 120px;
			position: absolute;
			left:0;
			top:10%;
			opacity: 0.4;
		}
		.shape__line {
			@include responsive (landscape) {
				display:block;
			}
			display: none;
			width: 200px;
			position: absolute;
			left:10%;
			bottom: 3%;
		}
	}
	.header-heading {
		z-index: 9;
	}
	h1 {
		@include responsive(phone) {font-size: 2.6rem}
		@include responsive(desktop) {
			font-size: 3.2rem
		}
		font-size: 1.8rem;
		font-weight: 700;
	}
	p {color: $color-tertiary}
	span {color: $color-primary;}
}

.header--sub{
	background: linear-gradient(0deg,#eaeaea 0,#f5f5f5 46%,#fff 100%);
	.container {
		position: absolute;
		left: 0;
		right: 0;
		margin: auto;
		top: 50%;
		transform: translateY(-20%);
		}
	}


.header--sub {
	min-height: 450px;
	position: relative;
	background-position: bottom right;
		.inner {
			h1{
				text-transform: uppercase;
				font-weight: 700;
				font-size: 2.5rem;
				color: $color-tertiary;
				span {
					text-transform: none;
					color: $color-primary;
					font-weight: 500;
					font-size: 0.8rem;
				}
			}
			p {
				font-weight: 400;
				font-size: 1rem;
				line-height: 1.8rem;
				display: block;
			}
			.image-right {
				display: none;
			}
		}
}


@media screen and (min-width: 992px) {
	.header--start, .header--sub { 
		min-height: 620px;
			.inner {
				.image-right {
					display: flex;
					justify-content: flex-end;
				}
			}
	}
	.header--sub .inner h1 { 
		font-size: 3rem;
	}
}

//=====================================> End
.id-header {
	 h1{
		 color: #fff;
		 line-height: 3.4rem;
		 font-weight: 400;
		 em{
			 font-family: 'Pacifico', cursive;
			 font-size: 3rem;
			 color: $color-secondary;
		 }
	 }
}
