//Blog===============================
#blog {
	.post {
		margin-bottom: 40px; 
		.inner {
			border: solid 2px #f3f3f3;
			background: #fff;
			transition-duration: .6s;
			position: relative;
			&:hover {
				border: solid 2px $color-primary;
			}
			.blog-teaser-content {
				padding: 20px;
				h3 {
					margin-top: 0;
					font-size: 1.6rem;
				}
				h4 {
					margin-top: 0;
					font-size: 1rem;
					color: $color-primary; 
				}
				.image_container {
					max-height: 250px;
					overflow-y: hidden;
					margin-bottom: 20px;
				}
				a {
				color:	#1c1e24;
				}
				.btn {
					position: absolute;
					bottom: 30px;
				}
			} 
		}
	}
}
 